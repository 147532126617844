import React from "react";

const ScheduleGrid = ({ date, onPatientSelect, view }) => {
  const rooms = ["Room 1", "Room 2", "Room 3"];
  const timeSlots = Array.from(
    { length: 13 },
    (_, i) => `${(i + 7).toString().padStart(2, "0")}:00`
  );

  const appointments = {
    "Room 1": [
      {
        start: "08:00",
        end: "09:30",
        patient: 'Jean "Lee Ann" Pham',
        procedure: "D3220",
        date: "2024-08-19", // Monday
      },
      {
        start: "10:30",
        end: "11:15",
        patient: "Anne Bennett",
        procedure: "",
        date: "2024-08-20", // Tuesday
      },
      {
        start: "12:00",
        end: "12:30",
        patient: "Anne Bennett",
        procedure: "Adult prophy, D1110, D0120, D0274",
        date: "2024-08-21", // Wednesday
      },
    ],
    "Room 2": [
      {
        start: "07:00",
        end: "08:30",
        patient: "Angela Olson",
        procedure: "Adult prophy, D0274, D1110, D0120",
        date: "2024-08-19", // Monday
      },
      {
        start: "08:30",
        end: "10:00",
        patient: "Joann Cooper",
        procedure: "Adult prophy, D0274, D1110, D0120",
        date: "2024-08-20", // Tuesday
      },
      {
        start: "10:00",
        end: "11:00",
        patient: "Annie Fox",
        procedure: "",
        date: "2024-08-21", // Wednesday
      },
    ],
    "Room 3": [
      {
        start: "07:00",
        end: "08:00",
        patient: 'Alma "Brad" Allan',
        procedure: "",
        date: "2024-08-19", // Monday
      },
      {
        start: "10:00",
        end: "11:30",
        patient: 'Cassandra "Cassie" Black',
        procedure: "D3330",
        date: "2024-08-20", // Tuesday
      },
      {
        start: "11:30",
        end: "13:00",
        patient: 'Julie "Matt" Fuller',
        procedure: "",
        date: "2024-08-21", // Wednesday
      },
    ],
  };

  const timeToMinutes = (time) => {
    const [hours, minutes] = time.split(":").map(Number);
    return hours * 60 + minutes - 7 * 60; // Subtract 7 hours (420 minutes) to align with 7am start
  };

  const renderAppointment = (
    room,
    appointment,
    isWeekView = false,
    dayIndex = 0
  ) => {
    const startMinutes = timeToMinutes(appointment.start);
    const endMinutes = timeToMinutes(appointment.end);
    const duration = endMinutes - startMinutes;

    return (
      <div
        key={`${room}-${appointment.start}-${appointment.date}`}
        className="absolute bg-blue-100 p-1 rounded cursor-pointer text-xs overflow-hidden"
        style={{
          top: `${startMinutes * (100 / 60)}%`,
          height: `${duration * (100 / 60)}%`,
          left: isWeekView ? `${dayIndex * 100}%` : "5%",
          width: isWeekView ? "100%" : "90%", // Adjust width for week view
        }}
        onClick={() => onPatientSelect(appointment.patient)}
      >
        <p className="font-bold">{appointment.patient}</p>
        {!isWeekView && <p>{appointment.procedure}</p>}
        <p className="text-xs text-gray-600">{`${appointment.start} - ${appointment.end}`}</p>
      </div>
    );
  };

  const getDaysInWeek = (date) => {
    const start = new Date(date);
    start.setDate(start.getDate() - start.getDay());
    return Array.from({ length: 7 }, (_, i) => {
      const day = new Date(start);
      day.setDate(day.getDate() + i);
      return day;
    });
  };

  const renderDayView = () => (
    <>
      {/* Header: Time and Rooms */}
      <div className="sticky top-0 z-10 bg-gray-200 font-bold">Time</div>
      {rooms.map((room) => (
        <div key={room} className="sticky top-0 z-10 bg-gray-200 font-bold">
          {room}
        </div>
      ))}

      {/* Time Slots and Appointments */}
      {timeSlots.map((time, index) => (
        <React.Fragment key={time}>
          <div className="border-t border-r p-1 relative h-16">
            <span className="absolute top-0 left-0 text-xs">{time}</span>
          </div>
          {rooms.map((room) => (
            <div
              key={`${room}-${time}`}
              className="border-t border-r p-1 relative h-16"
            >
              {/* Render Appointments for the current room and time slot */}
              {appointments[room]
                ?.filter((apt) => apt.date === date.toISOString().split("T")[0])
                .map((apt) => {
                  const aptStart = timeToMinutes(apt.start);
                  const aptEnd = timeToMinutes(apt.end);
                  const slotStart = timeToMinutes(time);
                  const nextSlotStart =
                    index < timeSlots.length - 1
                      ? timeToMinutes(timeSlots[index + 1])
                      : 24 * 60; // End of the day if it's the last time slot

                  const isWithinSlot =
                    (aptStart >= slotStart && aptStart < nextSlotStart) ||
                    (aptEnd > slotStart && aptEnd <= nextSlotStart) ||
                    (aptStart <= slotStart && aptEnd >= nextSlotStart);

                  return isWithinSlot ? renderAppointment(room, apt) : null;
                })}
            </div>
          ))}
        </React.Fragment>
      ))}
    </>
  );

  const renderWeekView = () => {
    const days = getDaysInWeek(date);
    return (
      <>
        <div className="sticky top-0 z-10 bg-gray-200 font-bold">Time</div>
        {days.map((day) => (
          <div
            key={day.toISOString()}
            className="sticky top-0 z-10 bg-gray-200 font-bold"
          >
            {day.toLocaleDateString("en-US", {
              weekday: "short",
              month: "numeric",
              day: "numeric",
            })}
          </div>
        ))}
        {timeSlots.map((time, index) => (
          <React.Fragment key={time}>
            <div className="border-t border-r p-1 relative">
              <span className="absolute top-0 left-0 text-xs">{time}</span>
            </div>
            {days.map((day, dayIndex) => (
              <div
                key={`${day.toISOString()}-${time}`}
                className="border-t border-r p-1 relative"
              >
                {Object.entries(appointments).flatMap(([room, apts]) =>
                  apts
                    .filter((apt) => {
                      const aptDate = apt.date;
                      const aptStart = timeToMinutes(apt.start);
                      const slotStart = timeToMinutes(time);
                      const nextSlotStart =
                        index < timeSlots.length - 1
                          ? timeToMinutes(timeSlots[index + 1])
                          : 24 * 60; // End of the day if it's the last time slot

                      return (
                        aptDate === day.toISOString().split("T")[0] &&
                        aptStart >= slotStart &&
                        aptStart < nextSlotStart
                      );
                    })
                    .map((apt) => renderAppointment(room, apt, true, dayIndex))
                )}
              </div>
            ))}
          </React.Fragment>
        ))}
      </>
    );
  };

  return (
    <div className="w-full overflow-x-auto">
      <div className="min-w-max">
        <div
          className={`grid gap-px bg-gray-300 ${
            view === "day" ? "grid-cols-4" : "grid-cols-8"
          }`}
          style={{ gridAutoRows: "minmax(60px, auto)" }}
        >
          {view === "day" ? renderDayView() : renderWeekView()}
        </div>
      </div>
    </div>
  );
};

export default ScheduleGrid;
