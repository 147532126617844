import React, { useEffect, useState } from "react";
import { createClient } from "@supabase/supabase-js";
import Avatar from "react-avatar";
import operaitorEmoji from "../assets/1024px.png";
import PatientList from "../components/PatientList"; // Adjust the import path as needed

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_KEY;
const supabase = createClient(supabaseUrl, supabaseAnonKey);

function MessagePage() {
  const [messages, setMessages] = useState([]);
  const [patients, setPatients] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);

  useEffect(() => {
    const fetchPatients = async () => {
      const { data, error } = await supabase
        .from("appointments") // Adjust table name as needed
        .select("*")
        .order("created_at", { ascending: false });

      if (error) {
        console.error("Error fetching patients:", error);
      } else {
        setPatients(data);
        if (data.length > 0) {
          setSelectedPatient(data[0]); // Set the most recent patient by default
        }
      }
    };

    fetchPatients();
  }, []);

  useEffect(() => {
    if (selectedPatient) {
      console.log("selected patient", selectedPatient);
      const fetchMessages = async () => {
        const { data, error } = await supabase
          .from("conversationLog")
          .select("*")
          .eq("sessionId", selectedPatient.id);

        if (error) {
          console.error("Error fetching messages:", error);
        } else {
          setMessages(data);
        }
      };

      fetchMessages();
    }
  }, [selectedPatient]);

  return (
    <div className="flex h-full">
      <PatientList patients={patients} onSelectPatient={setSelectedPatient} />
      <div className="flex-1 px-4 pt-3 overflow-auto">
        {selectedPatient ? (
          messages.length > 0 ? (
            messages.map((message) => (
              <div
                key={message.id}
                className={`flex items-start gap-2.5 mb-3 ${
                  message.role === "assistant" ? "justify-end" : "justify-start"
                }`}
              >
                {message.role === "assistant" ? (
                  <Avatar
                    name="AI Assistant"
                    src={operaitorEmoji}
                    round={true}
                    size="40"
                    className="w-8 h-8"
                  />
                ) : (
                  <Avatar
                    name={selectedPatient.patient_name}
                    round={true}
                    size="40"
                    className="w-8 h-8"
                  />
                )}
                <div
                  className={`flex flex-col w-full max-w-[320px] leading-1.5 p-4 border-gray-200 rounded-e-xl rounded-es-xl ${
                    message.role === "assistant"
                      ? "bg-blue-100 dark:bg-blue-700"
                      : "bg-gray-100 dark:bg-gray-700"
                  }`}
                >
                  <div className="flex items-center space-x-2 rtl:space-x-reverse">
                    <span className="text-sm font-semibold text-gray-900 dark:text-white">
                      {message.role}
                    </span>
                    <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
                      {new Date(message.created_at).toLocaleTimeString()}
                    </span>
                  </div>
                  <p className="text-sm font-normal py-1 text-gray-900 dark:text-white">
                    {message.message}
                  </p>
                  <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
                    {message.status}
                  </span>
                </div>
              </div>
            ))
          ) : (
            <p className="text-gray-500 dark:text-gray-400">
              No conversations found.
            </p>
          )
        ) : (
          <p className="text-gray-500 dark:text-gray-400">
            Select a patient to view conversations.
          </p>
        )}
      </div>
    </div>
  );
}

export default MessagePage;
