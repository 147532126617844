import React from "react";

const TeamMember = ({ name, role, imageUrl, linkedInUrl }) => {
  return (
    <div className="team-member">
      <div className="circle-image">
        <img src={imageUrl} alt={name} className="rounded-full w-60" />
      </div>
      <div className="relative flex flex-col items-center">
        <h3 className="text-lg font-semibold pt-2">{name}</h3>
        <p className="text-sm text-blue-600">{role}</p>
        <a
          href={linkedInUrl}
          rel="noopener noreferrer"
          className=" hover:underline"
        >
          LinkedIn
        </a>
      </div>
    </div>
  );
};

export default TeamMember;
