import React from "react";
import about from "./assets/about-01.jpg";
import me from "./assets/grad.png";
import katelyn from "./assets/katelyn.jpg";
import kev from "./assets/kevin.png";
import TeamMember from "./TeamMember";
import Footer from "./components/Footer";

function About() {
  const teamMembers = [
    {
      name: "John Lim",
      role: "CEO",
      imageUrl: me,
      linkedInUrl: "https://www.linkedin.com/in/johntaeyunlim/",
    },
    {
      name: "Katelyn Yang",
      role: "CTO",
      imageUrl: katelyn,
      linkedInUrl: "https://www.linkedin.com/in/katelyn-yang-b4570921a/",
    },
    {
      name: "Taehun Lim",
      role: "Lead Developer",
      imageUrl: kev,
      linkedInUrl: "https://www.linkedin.com/in/taehun-lim-948173b4/",
    },
  ];

  return (
    <div>
      <section className="relative">
        <div className="max-w-4xl mx-auto px-4 sm:px-6">
          <div className="pt-28 pb-12 md:pt-28 md:pb-12">
            <div className="text-center pb-12 md:pb-16">
              <h1
                className="text-5xl md:text-6xl font-extrabold leading-tighter tracking-tighter mb-4"
                data-aos="zoom-y-out"
              >
                We enable small businesses to thrive
                {/* <span className="block bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-teal-400"></span> */}
              </h1>
              <div className="max-w-4xl px-4 mx-auto">
                <p
                  className="text-xl text-gray-600 mb-8"
                  data-aos="zoom-y-out"
                  data-aos-delay="150"
                >
                  Partner with us to unlock your small business's full
                  potential, leveraging our innovative solutions and tailored
                  support for your success story
                </p>
                <img src={about} className="" alt="Logo" />
              </div>
            </div>
            <div className="text-3xl font-bold px-3 mx-auto mb-4">
              Our Story
            </div>
            <p
              className="text-lg text-gray-600 mb-8 px-3"
              data-aos="zoom-y-out"
              data-aos-delay="150"
            >
              Our journey began with a passion for empowering businesses of all
              sizes to achieve greatness. Inspired by the vision of fostering
              growth and success for small enterprises, we embarked on a mission
              to make a difference in the world. We recognized the challenges
              faced by small businesses and saw an opportunity to offer
              cutting-edge solutions that could bridge the gap between
              aspiration and achievement. <span className="block"></span> <br />
              Our success is a testament to the success of our clients. We take
              pride in being a trusted partner, offering not just innovative
              products but also personalized support every step of the way. Our
              story is about more than just the tools we provide; it's about
              building relationships, nurturing dreams, and enabling thriving
              businesses that make a difference in their communities. Join us in
              our story and let's build a brighter future together.
            </p>
          </div>
        </div>
      </section>
      <div className="my-0 border-b border-gray-300"></div>

      <section className="relative bg-[#fbfbfb]">
        <div className="max-w-3xl mx-auto px-4 sm:px-6">
          <div className="pt-24 pb-12 md:pt-24 md:pb-10">
            <div className="text-center pb-10 md:pb-10">
              <h2
                className="text-5xl md:text-5xl font-extrabold leading-tighter tracking-tighter mb-4"
                data-aos="zoom-y-out"
              >
                The humans behind the product
              </h2>
              <p
                className="text-lg text-gray-600 px-3"
                data-aos="zoom-y-out"
                data-aos-delay="150"
              >
                Here at Operaitor we focus on markets where technology,
                innovation, and capital can unlock long-term value and drive
                economic growth.
              </p>
            </div>
          </div>
        </div>

        <div className="flex justify-evenly">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-20 max-w-full mx-auto mb-12">
            {teamMembers.map((member, index) => (
              <TeamMember key={index} {...member} />
            ))}
          </div>
        </div>
      </section>
      <div className="my-0 border-b border-gray-300"></div>

      <Footer />
    </div>
  );
}

export default About;
