import React, { useState } from "react";
import { createClient } from "@supabase/supabase-js";

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_KEY;
const supabase = createClient(supabaseUrl, supabaseAnonKey);

const FileUploadPage = () => {
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!file) {
      setMessage("Please select a file first.");
      return;
    }

    setLoading(true);
    setMessage("");

    try {
      // Create a unique file name with a timestamp
      const fileName = `line dental/${Date.now()}_${file.name}`;

      // Upload file to Supabase
      const { data, error } = await supabase.storage
        .from("uploads") // Name of your bucket
        .upload(fileName, file, {
          cacheControl: "3600",
          upsert: false,
        });

      if (error) {
        throw error;
      }

      setMessage("File uploaded successfully.");
    } catch (error) {
      setMessage("An error occurred while uploading the file.");
      console.error("Upload error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-md mt-4 mx-auto p-4 border border-gray-300 rounded-lg shadow-lg">
      <h1 className="text-xl font-semibold mb-4">Secure File Upload</h1>
      <form onSubmit={handleSubmit} className="space-y-4">
        <input
          type="file"
          onChange={handleFileChange}
          className="border border-gray-300 p-2 rounded-lg w-full"
        />
        <button
          type="submit"
          disabled={loading}
          className={`py-2 px-4 rounded-lg text-white ${
            loading ? "bg-gray-500" : "bg-blue-500"
          } hover:bg-blue-600`}
        >
          {loading ? "Uploading..." : "Upload"}
        </button>
      </form>
      {message && <p className="mt-4 text-gray-600">{message}</p>}
    </div>
  );
};

export default FileUploadPage;
