import React, { useState } from "react";

const CollapsibleSection = ({ title, children }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="mb-4">
      <button
        className="w-full text-left font-bold py-2 px-4 bg-gray-200 hover:bg-gray-300 rounded flex justify-between items-center"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        {title}
        <span>{isExpanded ? "▲" : "▼"}</span>
      </button>
      {isExpanded && (
        <div className="mt-2 p-4 bg-white rounded shadow">{children}</div>
      )}
    </div>
  );
};

const PatientInfo = ({ patient }) => {
  if (!patient) {
    return (
      <aside className="w-1/4 bg-white rounded shadow p-4">
        <p>Select a patient to view details</p>
      </aside>
    );
  }

  const {
    name,
    phone,
    address,
    email,
    dob,
    insuranceProvider,
    policyNumber,
    appointments, // Assuming appointments is an array of appointment dates
    perioCharting, // Assuming perioCharting contains some charting data
    filesAndLetters, // Assuming this is an array of file names or descriptions
    correspondence, // Assuming this is an array of correspondence history
  } = patient;

  return (
    <aside
      className="w-1/4 bg-white rounded shadow p-4 overflow-y-auto"
      style={{ maxHeight: "calc(100vh - 100px)" }}
    >
      <div className="mb-4">
        {/* <img
          src={`${name.replace(" ", "-")}.jpg`}
          alt={name}
          className="w-20 h-20 rounded-full mx-auto"
        /> */}
        <h2 className="text-center font-bold mt-2">{name}</h2>
      </div>

      <CollapsibleSection title="Patient Information">
        <p>
          <strong>Phone:</strong> {phone}
        </p>
        <p>
          <strong>Address:</strong> {address}
        </p>
        <p>
          <strong>Email:</strong> {email}
        </p>
        <p>
          <strong>Date of Birth:</strong> {dob}
        </p>
        <p>
          <strong>Insurance:</strong> {insuranceProvider}
        </p>
        <p>
          <strong>Policy Number:</strong> {policyNumber}
        </p>
      </CollapsibleSection>

      <CollapsibleSection title="Appointments">
        {appointments && appointments.length > 0 ? (
          appointments.map((appointment, index) => (
            <p key={index}>{appointment}</p>
          ))
        ) : (
          <p>No appointments found</p>
        )}
      </CollapsibleSection>

      <CollapsibleSection title="Period Charting">
        <p>{perioCharting || "Perio charting details would go here..."}</p>
      </CollapsibleSection>

      <CollapsibleSection title="Files and Letters">
        {filesAndLetters && filesAndLetters.length > 0 ? (
          filesAndLetters.map((file, index) => <p key={index}>{file}</p>)
        ) : (
          <p>No files or letters found</p>
        )}
      </CollapsibleSection>

      <CollapsibleSection title="Correspondence">
        {correspondence && correspondence.length > 0 ? (
          correspondence.map((entry, index) => <p key={index}>{entry}</p>)
        ) : (
          <p>No correspondence history found</p>
        )}
      </CollapsibleSection>
    </aside>
  );
};

export default PatientInfo;
