import React from "react";
import MessageComponent from "../components/MessageComponent";
import ToDoList from "../components/ToDoList";

function admin() {
  return (
    <div className="flex flex-col md:flex-row h-full">
      <div className="w-full md:w-2/3 h-full overflow-auto">
        <MessageComponent />
      </div>
      <div className="w-full md:w-1/3 h-full overflow-auto bg-gray-50 p-4">
        <ToDoList />
      </div>
    </div>
  );
}

export default admin;
