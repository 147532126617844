import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import { createClient } from "@supabase/supabase-js";

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_KEY;
const supabase = createClient(supabaseUrl, supabaseAnonKey);

function Apply() {
  const navigate = useNavigate();

  const showToastMessage = () => {
    toast.success("Form Submitted!", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  const showFailMessage = () => {
    toast.error("Error with Form. Try again!", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: 0,
    country: "United States",
    fullAddress: "",
    address: "",
    city: "",
    state: "",
    zip: 0,
    businessName: "",
    message: "",
    plan: "",
  });

  function updateForm(value) {
    return setForm((prev) => {
      return { ...prev, ...value };
    });
  }

  async function onSubmit(e) {
    e.preventDefault();

    try {
      const { error } = await supabase.from("contactUsForm").insert([
        {
          first_name: form.firstName,
          last_name: form.lastName,
          email: form.email,
          phone_number: form.phoneNumber,
          country: form.country,
          full_address: form.fullAddress,
          address: form.address,
          city: form.city,
          state: form.state,
          zip: form.zip,
          business_name: form.businessName,
          message: form.message,
          plan: form.plan,
        },
      ]);

      if (error) {
        showFailMessage();
        console.error("Error inserting data:", error.message);
      }
    } catch (error) {
      showFailMessage("");
      console.error("Error inserting data:", error.message);
    }

    setForm({
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: 0,
      country: "United States",
      fullAddress: "",
      address: "",
      city: "",
      state: "",
      zip: 0,
      businessName: "",
      message: "",
      plan: "",
    });
    showToastMessage();
    navigate("/");
  }

  return (
    <div className="">
      <div className="m-14">
        <div className="text-xl font-extrabold mt-2">
          Apply for Consultation
        </div>
        <p className="text-sm text-gray-600 mb-2">
          Professional counselors will help you quickly and easily sign up for
          our operaitor.
        </p>
        <form className="border">
          <div className="space-y-12 m-12">
            <div className="border-b border-gray-900/10 pb-12">
              <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <label
                    htmlFor="first-name"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    First name
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="first-name"
                      id="first-name"
                      onChange={(e) =>
                        updateForm({ firstName: e.target.value })
                      }
                      autoComplete="given-name"
                      className="block p-2.5 w-full bg-gray-50 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    htmlFor="last-name"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Last name
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="last-name"
                      id="last-name"
                      onChange={(e) => updateForm({ lastName: e.target.value })}
                      autoComplete="family-name"
                      className="block p-2.5 w-full rounded-md bg-gray-50 border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="sm:col-span-4">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Email address
                  </label>
                  <div className="mt-2">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      onChange={(e) => updateForm({ email: e.target.value })}
                      autoComplete="email"
                      className="block p-2.5 w-full rounded-md bg-gray-50 border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="sm:col-span-4">
                  <label
                    htmlFor="number"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Phone Number
                  </label>
                  <div className="mt-2">
                    <input
                      id="tel"
                      name="tel"
                      type="tel"
                      onChange={(e) =>
                        updateForm({ phoneNumber: e.target.value })
                      }
                      placeholder="7181111111"
                      className="block p-2.5 w-full rounded-md bg-gray-50 border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    htmlFor="country"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Country
                  </label>
                  <div className="mt-2">
                    <select
                      id="country"
                      name="country"
                      onChange={(e) => updateForm({ country: e.target.value })}
                      autoComplete="country-name"
                      className="block p-2.5 w-full rounded-md bg-gray-50 border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                    >
                      <option>United States</option>
                      <option>Canada</option>
                      <option>South Korea</option>
                    </select>
                  </div>
                </div>

                <div className="col-span-full">
                  <label
                    htmlFor="street-address"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Store address
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      onChange={(e) => updateForm({ address: e.target.value })}
                      name="street-address"
                      id="street-address"
                      autoComplete="street-address"
                      className="block p-2.5 w-full rounded-md bg-gray-50 border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="sm:col-span-2 sm:col-start-1">
                  <label
                    htmlFor="city"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    City
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="city"
                      id="city"
                      onChange={(e) => updateForm({ city: e.target.value })}
                      autoComplete="address-level2"
                      className="block p-2.5 w-full rounded-md bg-gray-50 border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <label
                    htmlFor="region"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    State / Province
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="region"
                      id="region"
                      onChange={(e) => updateForm({ state: e.target.value })}
                      autoComplete="address-level1"
                      className="block p-2.5 w-full rounded-md bg-gray-50 border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <label
                    htmlFor="postal-code"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    ZIP / Postal code
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="postal-code"
                      id="postal-code"
                      onChange={(e) => updateForm({ zip: e.target.value })}
                      autoComplete="postal-code"
                      className="block p-2.5 w-full rounded-md bg-gray-50 border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="border-b border-gray-900/10 pb-12">
              <div>
                <div className="sm:col-span-2">
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    Business Name
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="restaurant-name"
                      id="restaurant-name"
                      onChange={(e) =>
                        updateForm({ businessName: e.target.value })
                      }
                      className="block p-2.5 w-full rounded-md bg-gray-50 border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              </div>
              <div className="mt-10">
                <label
                  for="message"
                  class="block mb-2 text-sm font-medium text-gray-900"
                >
                  Your message
                </label>
                <textarea
                  id="message"
                  rows="4"
                  onChange={(e) => updateForm({ message: e.target.value })}
                  class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Leave a descriptive message that might be useful for the consultation. "
                ></textarea>
              </div>

              <div className="mt-10 space-y-10">
                <fieldset>
                  <legend className="text-sm font-semibold leading-6 text-gray-900">
                    Choose a plan
                  </legend>
                  <p className="mt-1 text-sm leading-6 text-gray-600">
                    We currently offer two plans.
                  </p>
                  <div className="mt-6 space-y-6">
                    <div className="flex items-center gap-x-3">
                      <input
                        id="lite"
                        name="plan"
                        type="radio"
                        value="lite"
                        onChange={(e) => updateForm({ plan: e.target.value })}
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      />
                      <label
                        htmlFor="lite"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Lite
                      </label>
                    </div>
                    <div className="flex items-center gap-x-3">
                      <input
                        id="standard"
                        type="radio"
                        name="plan"
                        value="standard"
                        onChange={(e) => updateForm({ plan: e.target.value })}
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      />
                      <label
                        htmlFor="standard"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Standard
                      </label>
                    </div>
                  </div>
                </fieldset>
                <button
                  onClick={() => navigate("/")}
                  class="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-1 px-2 border border-blue-500 hover:border-transparent rounded"
                >
                  Learn More
                </button>
              </div>
            </div>
          </div>

          <div className="m-6 flex items-center justify-end gap-x-6">
            <button
              type="button"
              onClick={() => navigate("/")}
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              Cancel
            </button>
            <button
              type="submit"
              onClick={onSubmit}
              className="rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Apply;
