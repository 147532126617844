import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { DataGrid } from "@mui/x-data-grid";
import {
  fetchPatients,
  fetchPhone,
  fetchAddress,
} from "../backend/supabaseService";
import Typography from "@mui/material/Typography";

const columns = [
  { field: "id", headerName: "ID", width: 30 },
  { field: "patient_name", headerName: "Name", width: 150 },
  { field: "phone_number", headerName: "Phone Number", width: 150 },
  { field: "DoB", headerName: "DoB", width: 110 },
  // { field: "age", headerName: "Age", width: 150 },
  { field: "email", headerName: "Email", width: 230 },
  { field: "gender", headerName: "Gender", width: 150 },
  { field: "address", headerName: "Address", width: 350 },
  { field: "allergies", headerName: "Allergies", width: 150 },
  { field: "conditions", headerName: "Conditions", width: 150 },
  { field: "first_appointment", headerName: "First Appointment", width: 150 },
  { field: "last_appointment", headerName: "Last Appointment", width: 150 },
  { field: "treatment_type", headerName: "Description", width: 150 },
  { field: "billing_address", headerName: "Billing Address", width: 150 },
  { field: "insurance_provider", headerName: "Insurance Provider", width: 150 },
  {
    field: "insurance_policy_number",
    headerName: "Insurance Policy Number",
    width: 180,
  },
];

function formatTime(dateString) {
  const date = new Date(dateString);
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  const minutesStr = minutes < 10 ? "0" + minutes : minutes;
  return `${hours}:${minutesStr} ${ampm}`;
}

function formatDate(dateString) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

function formatAddress(address) {
  if (!address) return "";
  const { line1, line2, city, state, zip, country } = address;
  return `${line1}${
    line2 ? ", " + line2 : ""
  }, ${city}, ${state} ${zip}, ${country}`;
}

function PatientPage() {
  const [patients, setPatients] = useState([]);
  const [noUpcomingPatients, setNoUpcomingPatients] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    async function loadPatients() {
      const data = await fetchPatients();
      console.log("fetchpatients:", data);

      const formattedPatients = await Promise.all(
        data.map(async (patient) => {
          const { cell_phone_id, home_phone_id } = patient;
          const { address_id } = patient;
          const phoneMap = await fetchPhone(cell_phone_id, home_phone_id);
          const addressMap = await fetchAddress(address_id);

          // Prioritize cell phone, then home phone, with a fallback to an empty string
          const phoneNumber =
            phoneMap[cell_phone_id] || phoneMap[home_phone_id] || "";

          const CurveAddress = addressMap[address_id] || "";

          const commonData = {
            date: formatDate(new Date(patient.startTime)),
            patient_name: `${patient.firstName} ${patient.lastName}`,
            id: patient.id,
            // phone_number: phoneNumber ? `+1${phoneNumber}` : "N/A",
            phone_number: patient.cellPhone_number,
            confirmation_response: patient.confirmation_status,
            treatment_type: patient.description,
            provider: patient.provider_name,
            readOnly: true,
            patient: patient.patient,
            DoB: patient.dob,
            time: formatTime(patient.startTime),
            age: patient.age,
            email: patient.email,
            gender: patient.gender,
            allergies: patient.allergies,
            conditions: patient.conditions,
            first_appointment: patient.first_appointment,
            last_appointment: patient.last_appointment,
          };

          if (patient.source === "curveDental") {
            return {
              ...commonData,
              source: "curveDental",
              // address: formatAddress(CurveAddress),
              address: `${patient.address_line1}, ${patient.address_city}, ${patient.address_country}, ${patient.address_postal}`,
              billing_address: `${patient.billingAddress_line1}, ${patient.billingAddress_city}, ${patient.billingAddress_country}, ${patient.billingAddress_postal}`,
            };
          } else {
            return {
              ...commonData,
              source: "local",
              address: `${patient.address_line1}, ${patient.address_city}, ${patient.address_state}, ${patient.address_zip}`,
              billing_address: `${patient.billingAddress_line1}, ${patient.billingAddress_city}, ${patient.billing_address_state}, ${patient.billing_address_zip}`,
            };
          }
        })
      );

      setPatients(formattedPatients);

      const todayDate = new Date().toISOString().split("T")[0];
      const upcomingAppointments = data.filter(
        (appointment) => appointment.date >= todayDate
      );
      setNoUpcomingPatients(upcomingAppointments.length === 0);
    }

    loadPatients();
  }, []);

  const todayDate = new Date().toISOString().split("T")[0];

  const filteredRows = patients.filter((appointment) =>
    appointment.patient_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const displayedRows = noUpcomingPatients
    ? filteredRows
    : filteredRows.filter((appointment) => appointment.date >= todayDate);

  return (
    <div class="mt-4 mx-2">
      <Box sx={{ height: 520, width: "100%" }}>
        <Box sx={{ mb: 2 }}>
          <TextField
            label="Search by Patient Name"
            variant="outlined"
            fullWidth
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </Box>
        {displayedRows.length === 0 ? (
          <Typography variant="h6" align="center" sx={{ mt: 4 }}>
            Loading...
          </Typography>
        ) : (
          <DataGrid
            rows={displayedRows.map((appointment) => ({
              id: appointment.id,
              patient_name: appointment.patient_name,
              phone_number: appointment.phone_number,
              date: appointment.date,
              time: appointment.time,
              DoB: appointment.DoB,
              treatment_type: appointment.treatment_type,
              gender: appointment.gender,
              email: appointment.email,
              age: appointment.age,
              allergies: appointment.allergies,
              conditions: appointment.conditions,
              first_appointment: appointment.first_appointment,
              last_appointment: appointment.last_appointment,
              address: appointment.address,
              billing_address: appointment.billing_address,
            }))}
            columns={columns}
            loading={displayedRows.length === 0}
            rowHeight={38}
            checkboxSelection
            disableRowSelectionOnClick
          />
        )}
      </Box>
    </div>
  );
}

export default PatientPage;
