import React, { useEffect, useState } from "react";
import { createClient } from "@supabase/supabase-js";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_KEY;
const supabase = createClient(supabaseUrl, supabaseAnonKey);

function TodoList() {
  const [todos, setTodos] = useState([]);

  useEffect(() => {
    const fetchAppointments = async () => {
      const { data, error } = await supabase.from("appointments").select("*");

      if (error) {
        console.error("Error fetching appointments:", error);
      } else {
        const missingFields = data.flatMap((appointment) => {
          const fields = [];
          if (!appointment.patient_name)
            fields.push({ id: appointment.id, field: "patient_name" });
          if (!appointment.phone_number)
            fields.push({ id: appointment.id, field: "phone_number" });
          if (!appointment.date)
            fields.push({ id: appointment.id, field: "date" });
          if (!appointment.time)
            fields.push({ id: appointment.id, field: "time" });
          if (!appointment.DoB)
            fields.push({ id: appointment.id, field: "DoB" });
          if (!appointment.confirmation_status)
            fields.push({ id: appointment.id, field: "confirmation_status" });
          if (!appointment.patient_type)
            fields.push({ id: appointment.id, field: "patient_type" });
          if (!appointment.provider_name)
            fields.push({ id: appointment.id, field: "provider_name" });
          if (!appointment.insurance_id)
            fields.push({ id: appointment.id, field: "insurance_id" });
          if (appointment.reminder_sent === null)
            fields.push({ id: appointment.id, field: "reminder_sent" });
          if (!appointment.appointment_status)
            fields.push({ id: appointment.id, field: "appointment_status" });
          return fields;
        });
        setTodos(missingFields);
      }
    };

    fetchAppointments();
  }, []);

  const handleDelete = async (todo) => {
    // Optional: you can implement deletion of the missing field in the database if needed.
    setTodos((prevTodos) => prevTodos.filter((item) => item !== todo));
  };

  return (
    <div className="p-4">
      <h2 className="text-xl font-semibold mb-4">
        Todo List for Appointments Table
      </h2>
      {todos.length > 0 ? (
        <ul>
          {todos.map((todo, index) => (
            <li
              key={index}
              className="flex justify-between items-center mb-2 p-2 border-b"
            >
              <button
                onClick={() => handleDelete(todo)}
                className="bg-blue-500 text-white px-2 py-1 rounded flex items-center"
              >
                <CheckCircleOutlineIcon />
              </button>
              <span className="text-red-500">{`${todo.field} is missing in appointment ID: ${todo.id}`}</span>
            </li>
          ))}
        </ul>
      ) : (
        <p>No missing fields found!</p>
      )}
    </div>
  );
}

export default TodoList;
