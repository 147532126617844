import React, { useState } from "react";
import ScheduleGrid from "../components/ScheduleGrid";
import PatientInfo from "../components/PatientInfo";

const DentalScheduler = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [view, setView] = useState("day"); // 'day' or 'week'

  const navigateDate = (direction) => {
    const newDate = new Date(selectedDate);
    if (view === "day") {
      newDate.setDate(newDate.getDate() + direction);
    } else {
      newDate.setDate(newDate.getDate() + direction * 7);
    }
    setSelectedDate(newDate);
  };

  return (
    <div className="bg-gray-100 p-4 h-screen flex flex-col">
      <header className="flex justify-between items-center mb-4">
        <div className="flex items-center">
          {/* <img
            src="profile-pic.jpg"
            alt="Wanho Lew"
            className="w-10 h-10 rounded-full mr-2"
          /> */}
          <h1 className="text-xl font-bold">Wanho Lew</h1>
          <span className="ml-2 text-gray-600">Line Dental</span>
        </div>
        <div className="flex space-x-4 items-center">
          <button
            onClick={() => navigateDate(-1)}
            className="px-2 py-1 bg-blue-500 text-white rounded"
          >
            &lt; Prev
          </button>
          <span className="font-bold">{selectedDate.toDateString()}</span>
          <button
            onClick={() => navigateDate(1)}
            className="px-2 py-1 bg-blue-500 text-white rounded"
          >
            Next &gt;
          </button>

          <button
            className={`px-2 py-1 ${
              view === "week" ? "bg-blue-700" : "bg-blue-500"
            } text-white rounded`}
            onClick={() => setView("week")}
          >
            Week
          </button>
          <button
            className={`px-2 py-1 ${
              view === "day" ? "bg-blue-700" : "bg-blue-500"
            } text-white rounded`}
            onClick={() => setView("day")}
          >
            Day
          </button>
        </div>
      </header>

      <main className="flex flex-1 overflow-hidden">
        <ScheduleGrid
          date={selectedDate}
          onPatientSelect={setSelectedPatient}
          view={view}
        />
        <PatientInfo patient={selectedPatient} />
      </main>
    </div>
  );
};

export default DentalScheduler;
