import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

export function StatusEditCell({ id, value, api, field }) {
  const appointmentStatuses = [
    { value: null, label: "Scheduled" },
    { value: "Check In", label: "Check In" },
    { value: "Delete", label: "Delete" },
    { value: "Missed", label: "Missed" },
    { value: "Cancel", label: "Cancel" },
    { value: "Check Out", label: "Check Out" },
    { value: "Cut", label: "Cut" },
  ];

  const handleChange = (event) => {
    api.setEditCellValue({ id, field, value: event.target.value });
  };

  return (
    <Select
      value={value || ""}
      onChange={handleChange}
      autoWidth
      displayEmpty
      style={{ width: "100%" }}
    >
      {appointmentStatuses.map((status) => (
        <MenuItem key={status.value} value={status.value}>
          {status.label}
        </MenuItem>
      ))}
    </Select>
  );
}
