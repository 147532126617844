import React, { useState } from "react";
import moment from "moment";
import { AppointmentForm } from "@devexpress/dx-react-scheduler-material-ui";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import CakeIcon from "@mui/icons-material/Cake";
import Notes from "@mui/icons-material/Notes";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import Close from "@mui/icons-material/Close";
import CalendarToday from "@mui/icons-material/CalendarToday";
import Create from "@mui/icons-material/Create";
import "../styles/Appointmentform.css";

const AppointmentFormContainerBasic = ({
  visible,
  visibleChange,
  appointmentData,
  cancelAppointment,
  target,
  onHide,
  commitChanges,
}) => {
  const [selectedDate, setSelectedDate] = useState(
    moment(appointmentData.date).toDate()
  );
  const [selectedTime, setSelectedTime] = useState(
    moment(appointmentData.time, "HH:mm").toDate()
  );
  const [formData, setFormData] = useState(appointmentData);

  const handleDateChange = (date) => {
    const dateObject = moment(date, "YYYY-MM-DD").toDate();

    setSelectedDate(dateObject);
  };

  const handleTimeChange = (time) => {
    setSelectedTime(time);
    console.log("time", time);
  };

  const applyChanges = () => {
    const appointment = {
      ...formData,
      date: moment(selectedDate).format("YYYY-MM-DD"),
      time: moment(selectedTime).format("HH:mm"),
    };

    const type = appointmentData.id === undefined ? "added" : "changed";
    const newAppointment = {
      ...appointment,
      startDate: appointment.date,
    };
    console.log("new type: ", type);
    console.log("newAppointment", newAppointment);
    commitChanges({ [type]: newAppointment });

    setSelectedDate(null);
    setSelectedTime(null);
    setFormData(appointmentData);

    visibleChange();
  };

  const cancelChanges = () => {
    visibleChange();
    cancelAppointment();
  };

  const textEditorProps = (field) => {
    let label;
    if (field === "title") {
      label = "Patient Name";
    } else if (field === "phone_number") {
      label = "Phone Number";
    } else if (field === "DoB") {
      label = "Date of Birth";
    } else {
      label = field[0].toUpperCase() + field.slice(1);
    }

    const handleChange = (event) => {
      const { value } = event.target;
      setFormData((prevData) => ({ ...prevData, [field]: value }));
    };

    return {
      variant: "outlined",
      onChange: handleChange,

      value: formData[field] || "",
      label: label,
      className: "Demo-textField",
    };
  };

  return (
    <AppointmentForm.Overlay
      visible={visible}
      target={target}
      fullSize
      onHide={onHide}
    >
      <div>
        <div className="Demo-header">
          <IconButton
            className="Demo-closeButton"
            onClick={cancelChanges}
            size="large"
          >
            <Close color="action" />
          </IconButton>
        </div>
        <div className="Demo-content">
          <div className="Demo-wrapper">
            <Create className="Demo-icon" color="action" />
            <TextField {...textEditorProps("title")} />
          </div>
          <div className="Demo-wrapper">
            <CalendarToday className="Demo-icon" color="action" />
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label="Date"
                value={selectedDate}
                onChange={handleDateChange}
                renderInput={(props) => (
                  <TextField className="Demo-picker" {...props} />
                )}
                inputFormat="YYYY-MM-DD"
                placeholder="YYYY-MM-DD"
              />
              <TimePicker
                label="Time"
                value={selectedTime}
                onChange={handleTimeChange}
                renderInput={(props) => (
                  <TextField className="Demo-picker" {...props} />
                )}
                inputFormat="HH:mm"
              />
            </LocalizationProvider>
          </div>
          <div className="Demo-wrapper">
            <LocalPhoneIcon className="Demo-icon" color="action" />
            <TextField
              placeholder="917-111-1111"
              {...textEditorProps("phone_number")}
            />
          </div>
          <div className="Demo-wrapper">
            <CakeIcon className="Demo-icon" color="action" />
            <TextField placeholder="YYYY-MM-DD" {...textEditorProps("DoB")} />
          </div>

          <div className="Demo-wrapper">
            <Notes className="Demo-icon" color="action" />
            <TextField {...textEditorProps("notes")} multiline rows="6" />
          </div>
        </div>
        <div className="Demo-buttonGroup">
          <Button
            variant="outlined"
            color="secondary"
            className="Demo-button"
            onClick={() => {
              visibleChange();
              commitChanges({ deleted: appointmentData.id });
            }}
          >
            Delete
          </Button>
          <Button
            variant="outlined"
            color="primary"
            className="Demo-button"
            onClick={() => {
              visibleChange();
              applyChanges();
            }}
          >
            {appointmentData.id === undefined ? "Create" : "Save"}
          </Button>
        </div>
      </div>
    </AppointmentForm.Overlay>
  );
};

export default AppointmentFormContainerBasic;
