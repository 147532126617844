import React from "react";
import { useState, useRef, useEffect, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

import thumb from "./assets/thumb.png";

import howitworks1 from "./assets/howitworks1.jpg";
import howitworks2 from "./assets/howitworks2.jpg";
import howitworks3 from "./assets/howitworks3.jpg";

import Footer from "./components/Footer";
import operaitorDemo1 from "./assets/Operaitordemo1.mp4";

function HowitWorks() {
  const [tab, setTab] = useState(1);
  const tabs = useRef(null);

  const [modalOpen, setModalOpen] = useState(false);
  const videoRef = useRef(null);

  const heightFix = () => {
    if (tabs.current && tabs.current.parentElement)
      tabs.current.parentElement.style.height = `${tabs.current.clientHeight}px`;
  };

  useEffect(() => {
    heightFix();
  }, []);

  const thumbWidth = 768;
  const thumbHeight = 432;
  const thumbAlt = "Modal Video Thumbnail";

  return (
    <div>
      <section className="relative">
        <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
          <div className="pt-12 md:pt-20">
            {/* Section header */}
            <div className="max-w-3xl mx-auto text-center pb-12 md:pb-10">
              <h1 className="text-4xl font-extrabold mb-4">The Solution.</h1>
              <p className="text-xl text-gray-600">
                Operator streamlines your front desk with a virtual receptionist
                powered by AI, eliminating the need for retraining and ensuring
                patients reach the right person quickly, improving patient
                experience and staff efficiency.
              </p>
            </div>

            <div>
              {/* Video thumbnail */}
              <div>
                <div
                  className="relative flex justify-center mb-8"
                  data-aos="zoom-y-out"
                  data-aos-delay="450"
                >
                  <div className="flex flex-col justify-center">
                    <img
                      src={thumb}
                      width={thumbWidth}
                      height={thumbHeight}
                      alt={thumbAlt}
                    />
                  </div>
                  <button
                    className="absolute top-full flex items-center transform -translate-y-1/2 bg-white rounded-full font-medium group p-4 shadow-lg"
                    onClick={() => {
                      setModalOpen(true);
                    }}
                  >
                    <svg
                      className="w-6 h-6 fill-current text-gray-400 group-hover:text-blue-600 shrink-0"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm0 2C5.373 24 0 18.627 0 12S5.373 0 12 0s12 5.373 12 12-5.373 12-12 12z" />
                      <path d="M10 17l6-5-6-5z" />
                    </svg>
                    <span className="ml-3">Watch the full video (2 min)</span>
                  </button>
                </div>
              </div>
              {/* End: Video thumbnail */}

              <Transition
                show={modalOpen}
                as={Fragment}
                afterEnter={() => videoRef.current?.play()}
              >
                <Dialog
                  initialFocus={videoRef}
                  onClose={() => setModalOpen(false)}
                >
                  {/* Modal backdrop */}
                  <Transition.Child
                    className="fixed inset-0 z-[99999] bg-black bg-opacity-75 transition-opacity"
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition ease-out duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    aria-hidden="true"
                  />
                  {/* End: Modal backdrop */}

                  {/* Modal dialog */}
                  <Transition.Child
                    className="fixed inset-0 z-[99999] overflow-hidden flex items-center justify-center transform px-4 sm:px-6"
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ttransition ease-out duration-200"
                    leaveFrom="oopacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <div className="max-w-6xl mx-auto h-full flex items-center">
                      <Dialog.Panel className="w-full max-h-full aspect-video bg-black overflow-hidden">
                        <iframe
                          width={thumbWidth}
                          height={thumbHeight}
                          src={operaitorDemo1}
                          // src="https://www.youtube.com/embed/BF1K8zCCIM0?si=aZD34kv9MmmsHfRu"
                          title="YouTube video player"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowfullscreen
                        ></iframe>
                      </Dialog.Panel>
                    </div>
                  </Transition.Child>
                  {/* End: Modal dialog */}
                </Dialog>
              </Transition>
            </div>

            {/* Section content */}
            <div className="md:grid md:grid-cols-12 md:gap-6">
              {/* Content */}
              <div
                className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6 md:mt-6"
                data-aos="fade-right"
              >
                <div className="md:pr-4 lg:pr-12 xl:pr-16 mb-8">
                  <h3 className="text-3xl font-bold mb-3">
                    Operaitor Service Scenario
                  </h3>
                  <p className="text-xl text-gray-600">
                    Leave it to Operaitor to answer on your behalf and we will
                    handle the rest.
                  </p>
                </div>
                {/* Tabs buttons */}
                <div className="mb-8 md:mb-0">
                  <a
                    className={`flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 ${
                      tab !== 1
                        ? "bg-white shadow-md border-gray-200 hover:shadow-lg"
                        : "bg-gray-200 border-transparent"
                    }`}
                    href="#0"
                    onClick={(e) => {
                      e.preventDefault();
                      setTab(1);
                    }}
                  >
                    <div>
                      <div className="font-bold leading-snug tracking-tight mb-1">
                        1. Customer Calls or Texts an Inquiry
                      </div>
                      <div className="text-gray-600">
                        Operator's AI sends tailored responses to patient using
                        pretrained medical knowledge as well as predefined rules
                        tailored to your specific practice's needs.
                      </div>
                    </div>
                    <div className="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow flex-shrink-0 ml-3">
                      <svg
                        className="w-3 h-3 fill-current"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.854.146a.5.5 0 00-.525-.116l-11 4a.5.5 0 00-.015.934l4.8 1.921 1.921 4.8A.5.5 0 007.5 12h.008a.5.5 0 00.462-.329l4-11a.5.5 0 00-.116-.525z"
                          fillRule="nonzero"
                        />
                      </svg>
                    </div>
                  </a>
                  <a
                    className={`flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 ${
                      tab !== 2
                        ? "bg-white shadow-md border-gray-200 hover:shadow-lg"
                        : "bg-gray-200 border-transparent"
                    }`}
                    href="#0"
                    onClick={(e) => {
                      e.preventDefault();
                      setTab(2);
                    }}
                  >
                    <div>
                      <div className="font-bold leading-snug tracking-tight mb-1">
                        2. Execution
                      </div>
                      <div className="text-gray-600">
                        Operaitor handles appointment scheduling, check-in and
                        check-out. Automatically updating PMS.
                      </div>
                    </div>
                    <div className="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow flex-shrink-0 ml-3">
                      <svg
                        className="w-3 h-3 fill-current"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M11.953 4.29a.5.5 0 00-.454-.292H6.14L6.984.62A.5.5 0 006.12.173l-6 7a.5.5 0 00.379.825h5.359l-.844 3.38a.5.5 0 00.864.445l6-7a.5.5 0 00.075-.534z" />
                      </svg>
                    </div>
                  </a>
                  <a
                    className={`flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 ${
                      tab !== 3
                        ? "bg-white shadow-md border-gray-200 hover:shadow-lg"
                        : "bg-gray-200 border-transparent"
                    }`}
                    href="#0"
                    onClick={(e) => {
                      e.preventDefault();
                      setTab(3);
                    }}
                  >
                    <div>
                      <div className="font-bold leading-snug tracking-tight mb-1">
                        3. Status Management
                      </div>
                      <div className="text-gray-600">
                        Doctors can track appointment status and patient
                        inquiries through the Operaitor App.
                      </div>
                    </div>
                    <div className="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow flex-shrink-0 ml-3">
                      <svg
                        className="w-3 h-3 fill-current"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.334 8.06a.5.5 0 00-.421-.237 6.023 6.023 0 01-5.905-6c0-.41.042-.82.125-1.221a.5.5 0 00-.614-.586 6 6 0 106.832 8.529.5.5 0 00-.017-.485z"
                          fill="#191919"
                          fillRule="nonzero"
                        />
                      </svg>
                    </div>
                  </a>
                </div>
              </div>

              {/* Tabs items */}
              <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:order-1 mt-16">
                <div className="transition-all">
                  <div
                    className="relative flex flex-col text-center lg:text-right"
                    data-aos="zoom-y-out"
                    ref={tabs}
                  >
                    {/* Item 1 */}
                    <Transition
                      show={tab === 1}
                      appear={true}
                      className="w-full"
                      enter="transition ease-in-out duration-700 transform order-first"
                      enterFrom="opacity-0 translate-y-16"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in-out duration-300 transform absolute"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 -translate-y-16"
                      beforeEnter={() => heightFix()}
                      unmount={false}
                    >
                      <div className="relative inline-flex flex-col">
                        <img
                          className="md:max-w-none mx-auto rounded"
                          src={howitworks1}
                          width={500}
                          height="462"
                          alt="Features bg"
                        />
                      </div>
                    </Transition>
                    {/* Item 2 */}
                    <Transition
                      show={tab === 2}
                      appear={true}
                      className="w-full"
                      enter="transition ease-in-out duration-700 transform order-first"
                      enterFrom="opacity-0 translate-y-16"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in-out duration-300 transform absolute"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 -translate-y-16"
                      beforeEnter={() => heightFix()}
                      unmount={false}
                    >
                      <div className="relative inline-flex flex-col">
                        <img
                          className="md:max-w-none mx-auto rounded"
                          src={howitworks2}
                          width={500}
                          height="462"
                          alt="Features bg"
                        />
                      </div>
                    </Transition>
                    {/* Item 3 */}
                    <Transition
                      show={tab === 3}
                      appear={true}
                      className="w-full"
                      enter="transition ease-in-out duration-700 transform order-first"
                      enterFrom="opacity-0 translate-y-16"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in-out duration-300 transform absolute"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 -translate-y-16"
                      beforeEnter={() => heightFix()}
                      unmount={false}
                    >
                      <div className="relative inline-flex flex-col">
                        <img
                          className="md:max-w-none mx-auto rounded"
                          src={howitworks3}
                          width={500}
                          height="462"
                          alt="Features bg"
                        />
                      </div>
                    </Transition>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Email subscription */}
      <section className="mt-16">
        <div className="max-w-6xl mx-auto px-4 sm:px-6">
          <div className="pb-12 md:pb-20">
            {/* CTA box */}
            <div
              className="relative bg-gray-900 rounded py-10 px-8 md:py-16 md:px-12 shadow-2xl overflow-hidden"
              data-aos="zoom-y-out"
            >
              {/* Background illustration */}
              <div
                className="absolute right-0 bottom-0 pointer-events-none hidden lg:block"
                aria-hidden="true"
              >
                <svg
                  width="428"
                  height="328"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <defs>
                    <radialGradient
                      cx="35.542%"
                      cy="34.553%"
                      fx="35.542%"
                      fy="34.553%"
                      r="96.031%"
                      id="ni-a"
                    >
                      <stop stopColor="#DFDFDF" offset="0%" />
                      <stop stopColor="#4C4C4C" offset="44.317%" />
                      <stop stopColor="#333" offset="100%" />
                    </radialGradient>
                  </defs>
                  <g fill="none" fillRule="evenodd">
                    <g fill="#FFF">
                      <ellipse
                        fillOpacity=".04"
                        cx="185"
                        cy="15.576"
                        rx="16"
                        ry="15.576"
                      />
                      <ellipse
                        fillOpacity=".24"
                        cx="100"
                        cy="68.402"
                        rx="24"
                        ry="23.364"
                      />
                      <ellipse
                        fillOpacity=".12"
                        cx="29"
                        cy="251.231"
                        rx="29"
                        ry="28.231"
                      />
                      <ellipse
                        fillOpacity=".64"
                        cx="29"
                        cy="251.231"
                        rx="8"
                        ry="7.788"
                      />
                      <ellipse
                        fillOpacity=".12"
                        cx="342"
                        cy="31.303"
                        rx="8"
                        ry="7.788"
                      />
                      <ellipse
                        fillOpacity=".48"
                        cx="62"
                        cy="126.811"
                        rx="2"
                        ry="1.947"
                      />
                      <ellipse
                        fillOpacity=".12"
                        cx="78"
                        cy="7.072"
                        rx="2"
                        ry="1.947"
                      />
                      <ellipse
                        fillOpacity=".64"
                        cx="185"
                        cy="15.576"
                        rx="6"
                        ry="5.841"
                      />
                    </g>
                    <circle fill="url(#ni-a)" cx="276" cy="237" r="200" />
                  </g>
                </svg>
              </div>

              <div className="relative flex flex-col lg:flex-row justify-between items-center">
                {/* CTA content */}
                <div className="text-center lg:text-left lg:max-w-xl">
                  <h3 className="text-3xl font-extrabold text-white mb-2">
                    Powering your Business
                  </h3>
                  <p className="text-gray-300 text-lg mb-6">
                    Stay connected and power up your business with exclusive
                    offers, industry insights, and updates by subscribing to our
                    email list.
                  </p>

                  {/* CTA form */}
                  <form className="w-full lg:w-auto">
                    <div className="flex flex-col sm:flex-row justify-center max-w-xs mx-auto sm:max-w-md lg:mx-0">
                      <input
                        type="email"
                        className="form-input w-full appearance-none bg-gray-800 border border-gray-700 focus:border-gray-600 rounded-sm px-4 py-3 mb-2 sm:mb-0 sm:mr-2 text-white placeholder-gray-500"
                        placeholder="Your email…"
                        aria-label="Your email…"
                      />
                      <a
                        className="btn text-white bg-blue-600 hover:bg-blue-700 py-3 px-6 rounded-md shadow"
                        href="#0"
                      >
                        Subscribe
                      </a>
                    </div>
                    {/* Success message */}
                    {/* <p className="text-sm text-gray-400 mt-3">Thanks for subscribing!</p> */}
                    <p className="text-sm text-gray-400 mt-3">
                      No spam. You can unsubscribe at any time.
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default HowitWorks;
