import React, { useState } from "react";
import Avatar from "react-avatar";

function PatientList({ patients, onSelectPatient }) {
  const [searchQuery, setSearchQuery] = useState("");

  // Filter patients based on search query
  const filteredPatients = patients.filter((patient) =>
    patient.patient_name
      ? patient.patient_name.toLowerCase().includes(searchQuery.toLowerCase())
      : false
  );

  // Format created_at timestamp to show only hour and minute
  const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  };

  return (
    <div className="w-64 bg-gray-200 dark:bg-gray-800 p-4 h-full overflow-auto">
      <div className="mb-4">
        <input
          type="text"
          placeholder="Search patients..."
          className="w-full px-3 py-2 rounded-md border-gray-300 dark:border-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-300"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>
      <ul>
        {filteredPatients.map((patient) => (
          <li
            key={patient.id}
            className="cursor-pointer p-2 hover:bg-gray-300 dark:hover:bg-gray-700"
            onClick={() => onSelectPatient(patient)}
          >
            <div className="flex items-center mb-1">
              <Avatar
                name={patient.patient_name}
                round={true}
                size="40"
                className="flex-shrink-0 mr-2"
              />
              <div className="flex-1">
                <div className="flex justify-between items-center">
                  <span className="font-semibold">{patient.patient_name}</span>
                  <span className="text-xs text-gray-500 dark:text-gray-400">
                    {formatTime(patient.created_at)}
                  </span>
                </div>
                <span className="text-xs text-gray-600 dark:text-gray-400 mt-1">
                  {patient.phone_number}
                </span>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default PatientList;
