import React, { useState, useEffect } from "react";
import lineDental from "../assets/lineDental.png";
import { createClient } from "@supabase/supabase-js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_KEY;
const supabase = createClient(supabaseUrl, supabaseAnonKey);

function SettingsPage() {
  const [generalInfo, setGeneralInfo] = useState({
    email: "",
    phoneNumber: "",
    organization: "",
    role: "",
  });

  const [passwordInfo, setPasswordInfo] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const handleGeneralInfoChange = (e) => {
    const { name, value } = e.target;
    setGeneralInfo((prev) => ({ ...prev, [name]: value }));
  };

  const updateGeneralInfo = async () => {
    try {
      if (!currentProvider || !currentProvider.id) {
        console.error("Current provider or provider ID is missing");
        return { success: false, error: "Provider ID is missing" };
      }

      const updateData = {
        email: generalInfo.email || null,
        phone_number: generalInfo.phoneNumber || null,
        organization: generalInfo.organization || null,
        role: generalInfo.role || null,
      };

      console.log("currentProvider.id: ", currentProvider.id);
      console.log("updateData: ", updateData);

      // First, let's check if the row exists
      const { data: existingData, error: selectError } = await supabase
        .from("providers")
        .select("*")
        .eq("id", currentProvider.id)
        .single();

      if (selectError) {
        console.error("Error checking existing data:", selectError.message);
        return { success: false, error: selectError };
      }

      if (!existingData) {
        console.error("No existing row found for this ID");
        return { success: false, error: "No existing row found" };
      }

      console.log("Existing data:", existingData);

      // Now proceed with the update
      const { data, error } = await supabase
        .from("providers")
        .update(updateData)
        .eq("id", currentProvider.id)
        .select();

      if (error) {
        toast.error("Error updating information");
        console.error("Error updating general information:", error.message);
        return { success: false, error };
      }

      if (data && data.length > 0) {
        toast.success("Update successful!");
        console.log("Update successful:", data[0]);
        return { success: true, data: data[0] };
      } else {
        console.error("No data returned after update");
        return { success: false, error: "No data returned" };
      }
    } catch (err) {
      console.error("Unexpected error:", err);
      return { success: false, error: err };
    }
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswordInfo((prev) => ({ ...prev, [name]: value }));
  };

  // password change
  const submitNewPassword = async (e) => {
    e.preventDefault();
    const { newPassword, confirmPassword } = passwordInfo;

    if (newPassword.value !== confirmPassword.value) {
      console.error("New passwords don't match");
      return;
    }

    const { error } = await supabase.auth.updateUser({
      password: newPassword.value,
    });

    if (error) {
      toast.error(error.message);
    } else {
      toast.success("Password updated successfully");
    }

    // add supabase auth
  };

  const [features, setFeatures] = useState({
    callHandler: false,
    smsReminder: false,
    scheduling: false,
    insuranceClaim: false,
  });

  const [currentProvider, setCurrentProvider] = useState(null);

  useEffect(() => {
    fetchCurrentProvider();
  }, []);

  useEffect(() => {
    if (currentProvider) {
      fetchUserFeatures();
    }
  }, [currentProvider]);

  // for features
  const fetchCurrentProvider = async () => {
    const {
      data: { user },
      error,
    } = await supabase.auth.getUser();

    if (error) {
      console.error("Error fetching current user:", error);
    } else {
      setCurrentProvider(user);
    }
  };

  const fetchUserFeatures = async () => {
    if (!currentProvider) return;

    const { data, error } = await supabase
      .from("provider_features")
      .select("feature_id, enabled")
      .eq("provider_id", currentProvider.id);

    if (error) {
      console.error("Error fetching user features:", error);
    } else {
      const featureState = {
        callHandler: data.find((f) => f.feature_id === 1)?.enabled || false,
        smsReminder: data.find((f) => f.feature_id === 2)?.enabled || false,
        scheduling: data.find((f) => f.feature_id === 3)?.enabled || false,
        insuranceClaim: data.find((f) => f.feature_id === 4)?.enabled || false,
      };
      setFeatures(featureState);
    }
  };

  const handleFeatureChange = (e) => {
    const { id, checked } = e.target;

    setFeatures((prev) => {
      const newFeatures = { ...prev, [id]: checked };
      console.log("New features state:", newFeatures);
      return newFeatures;
    });
  };

  const updateFeatures = async () => {
    if (!currentProvider) return;

    const featuresToUpdate = [
      { feature_id: 1, name: "callHandler" },
      { feature_id: 2, name: "smsReminder" },
      { feature_id: 3, name: "scheduling" },
      { feature_id: 4, name: "insuranceClaim" },
    ];

    for (const feature of featuresToUpdate) {
      const { data, error } = await supabase
        .from("provider_features")
        .update({ enabled: Boolean(features[feature.name]) })
        .match({
          provider_id: currentProvider.id,
          feature_id: feature.feature_id,
        });

      if (error) {
        toast.error("Error updating feature");
      }
    }
    toast.success("Feature updated successfully!");
  };

  return (
    <div>
      <div class="grid grid-cols-1 px-4 pt-6 xl:grid-cols-3 xl:gap-4 dark:bg-gray-900">
        <div class="mb-4 col-span-full xl:mb-2">
          <nav class="flex mb-5" aria-label="Breadcrumb">
            <ol class="inline-flex items-center space-x-1 text-sm font-medium md:space-x-2">
              <li class="inline-flex items-center">
                <a
                  href="/calendar"
                  class="inline-flex items-center text-gray-700 hover:text-blue-600 dark:text-gray-300 dark:hover:text-white"
                >
                  <svg
                    class="w-5 h-5 mr-2.5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
                  </svg>
                  Home
                </a>
              </li>

              <li>
                <div class="flex items-center">
                  <svg
                    class="w-6 h-6 text-gray-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span
                    class="ml-1 text-gray-400 md:ml-2 dark:text-gray-500"
                    aria-current="page"
                  >
                    Settings
                  </span>
                </div>
              </li>
            </ol>
          </nav>
          <h1 class="text-xl font-semibold text-gray-900 sm:text-2xl dark:text-white">
            User settings
          </h1>
        </div>

        <div class="col-span-full xl:col-auto">
          <div class="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800">
            <div class="items-center sm:flex xl:block 2xl:flex sm:space-x-4 xl:space-x-0 2xl:space-x-4">
              <img
                class="mb-4 rounded-lg w-28 h-28 sm:mb-0 xl:mb-4 2xl:mb-0"
                src={lineDental}
                alt="line dental picture"
              />
              <div>
                <h3 class="mb-1 text-xl font-bold text-gray-900 dark:text-white">
                  Line Dental
                </h3>
                <div class="mb-4 text-sm text-gray-500 dark:text-gray-400">
                  Dr. Wanho Lew | Dr. Yoon Ji Seon
                </div>
              </div>
            </div>
          </div>
          <div class="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800">
            <div class="flow-root">
              <h3 class="text-xl font-semibold dark:text-white">
                Features & Services
              </h3>
              <p class="text-sm font-normal text-gray-500 dark:text-gray-400">
                You can manage which features you want to use.
              </p>
              <div class="divide-y divide-gray-200 dark:divide-gray-700">
                <div class="flex items-center justify-between py-4">
                  <div class="flex flex-col flex-grow">
                    <div class="text-lg font-semibold text-gray-900 dark:text-white">
                      Operaitor Call Handler
                    </div>
                    <div class="text-base font-normal text-gray-500 dark:text-gray-400">
                      Operaitor picks up the phone on your behalf
                    </div>
                  </div>
                  <label
                    for="callHandler"
                    class="relative flex items-center cursor-pointer"
                  >
                    <input
                      type="checkbox"
                      id="callHandler"
                      class="peer sr-only"
                      checked={features.callHandler}
                      onChange={handleFeatureChange}
                      // checked
                    />
                    <div class="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:top-[2px] after:left-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none dark:border-gray-600 dark:bg-gray-700"></div>{" "}
                  </label>
                </div>

                <div class="flex items-center justify-between py-4">
                  <div class="flex flex-col flex-grow">
                    <div class="text-lg font-semibold text-gray-900 dark:text-white">
                      SMS appointment confirmation
                    </div>
                    <div class="text-base font-normal text-gray-500 dark:text-gray-400">
                      Sends a sms reminder prior to appointment
                    </div>
                  </div>
                  <label
                    for="smsReminder"
                    class="relative flex items-center cursor-pointer"
                  >
                    <input
                      type="checkbox"
                      id="smsReminder"
                      class="peer sr-only"
                      checked={features.smsReminder}
                      onChange={handleFeatureChange}
                    />
                    <div class="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:top-[2px] after:left-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none dark:border-gray-600 dark:bg-gray-700"></div>{" "}
                  </label>
                </div>

                <div class="flex items-center justify-between py-4">
                  <div class="flex flex-col flex-grow">
                    <div class="text-lg font-semibold text-gray-900 dark:text-white">
                      Scheduling & Appointment Software
                    </div>
                    <div class="text-base font-normal text-gray-500 dark:text-gray-400">
                      Calendar software to manage appointments with ease
                    </div>
                  </div>
                  <label
                    for="scheduling"
                    class="relative inline-flex cursor-pointer items-center "
                  >
                    <input
                      type="checkbox"
                      id="scheduling"
                      class="peer sr-only"
                      checked={features.scheduling}
                      onChange={handleFeatureChange}
                      // checked
                    />
                    <div class="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:top-[2px] after:left-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none dark:border-gray-600 dark:bg-gray-700"></div>{" "}
                  </label>
                </div>

                <div class="flex items-center justify-between pt-4">
                  <div class="flex flex-col flex-grow">
                    <div class="text-lg font-semibold text-gray-900 dark:text-white">
                      Insurance Claim
                    </div>
                    <div class="text-base font-normal text-gray-500 dark:text-gray-400">
                      Patient Insurance eligibility lookup
                    </div>
                  </div>
                  <label
                    for="insuranceClaim"
                    class="relative flex items-center cursor-pointer"
                  >
                    <input
                      type="checkbox"
                      id="insuranceClaim"
                      class="peer sr-only"
                      // checked={features.insuranceClaim}
                      // onChange={handleFeatureChange}
                      disabled
                    />
                    <div class="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:top-[2px] after:left-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none dark:border-gray-600 dark:bg-gray-700"></div>{" "}
                  </label>
                </div>
              </div>
              <div class="mt-4">
                <button
                  onClick={updateFeatures}
                  class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Save all
                </button>
              </div>
            </div>
          </div>
          {/* <div class="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800"></div> */}
        </div>
        <div class="col-span-2">
          <div class="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800">
            <h3 class="mb-4 text-xl font-semibold dark:text-white">
              General information
            </h3>

            <div class="grid grid-cols-6 gap-6">
              <div class="col-span-6 sm:col-span-3">
                <label
                  for="email"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={generalInfo.email}
                  onChange={handleGeneralInfoChange}
                  class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="example@company.com"
                  required
                />
              </div>
              <div class="col-span-6 sm:col-span-3">
                <label
                  for="phoneNumber"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Phone Number
                </label>
                <input
                  type="text"
                  name="phoneNumber"
                  id="phoneNumber"
                  value={generalInfo.phoneNumber}
                  onChange={handleGeneralInfoChange}
                  class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="e.g. +(123)456 789"
                  required
                />
              </div>

              <div class="col-span-6 sm:col-span-3">
                <label
                  for="organization"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Organization
                </label>
                <input
                  type="text"
                  name="organization"
                  id="organization"
                  value={generalInfo.organization}
                  onChange={handleGeneralInfoChange}
                  class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Company Name"
                  required
                />
              </div>
              <div class="col-span-6 sm:col-span-3">
                <label
                  for="role"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Role
                </label>
                <input
                  type="text"
                  name="role"
                  id="role"
                  value={generalInfo.role}
                  onChange={handleGeneralInfoChange}
                  class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Owner"
                  required
                />
              </div>

              <div class="col-span-6 sm:col-full">
                <button
                  class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  type="submit"
                  onClick={updateGeneralInfo}
                >
                  Save all
                </button>
              </div>
            </div>
          </div>
          <div class="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800">
            <h3 class="mb-4 text-xl font-semibold dark:text-white">
              Password information
            </h3>

            <div class="grid grid-cols-6 gap-6">
              <div class="col-span-6 sm:col-span-3">
                <label
                  for="currentPassword"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Current password
                </label>
                <input
                  type="text"
                  name="currentPassword"
                  value={passwordInfo.currentPassword}
                  onChange={handlePasswordChange}
                  class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="••••••••"
                  required
                />
              </div>
              <div class="col-span-6 sm:col-span-3">
                <label
                  for="newPassword"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  New password
                </label>
                <input
                  data-popover-target="popover-password"
                  data-popover-placement="bottom"
                  type="password"
                  name="newPassword"
                  value={passwordInfo.newPassword}
                  onChange={handlePasswordChange}
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="••••••••"
                  required
                />
                <div
                  data-popover
                  id="popover-password"
                  role="tooltip"
                  class="absolute z-10 invisible inline-block text-sm font-light text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 w-72 dark:bg-gray-800 dark:border-gray-600 dark:text-gray-400"
                >
                  <div class="p-3 space-y-2">
                    <h3 class="font-semibold text-gray-900 dark:text-white">
                      Must have at least 6 characters
                    </h3>
                    <div class="grid grid-cols-4 gap-2">
                      <div class="h-1 bg-orange-300 dark:bg-orange-400"></div>
                      <div class="h-1 bg-orange-300 dark:bg-orange-400"></div>
                      <div class="h-1 bg-gray-200 dark:bg-gray-600"></div>
                      <div class="h-1 bg-gray-200 dark:bg-gray-600"></div>
                    </div>
                    <p>It’s better to have:</p>
                    <ul>
                      <li class="flex items-center mb-1">
                        <svg
                          class="w-4 h-4 mr-2 text-green-400 dark:text-green-500"
                          aria-hidden="true"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        Upper & lower case letters
                      </li>
                      <li class="flex items-center mb-1">
                        <svg
                          class="w-4 h-4 mr-2 text-gray-300 dark:text-gray-400"
                          aria-hidden="true"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        A symbol (#$&)
                      </li>
                      <li class="flex items-center">
                        <svg
                          class="w-4 h-4 mr-2 text-gray-300 dark:text-gray-400"
                          aria-hidden="true"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        A longer password (min. 12 chars.)
                      </li>
                    </ul>
                  </div>
                  <div data-popper-arrow></div>
                </div>
              </div>
              <div class="col-span-6 sm:col-span-3">
                <label
                  for="confirm-password"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Confirm password
                </label>
                <input
                  type="text"
                  name="confirmPassword"
                  value={passwordInfo.confirmPassword}
                  onChange={handlePasswordChange}
                  class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="••••••••"
                  required
                />
              </div>
              <div class="col-span-6 sm:col-full">
                <button
                  class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  type="submit"
                  onClick={submitNewPassword}
                >
                  Save all
                </button>
              </div>
            </div>
          </div>
          {/* <div class="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800">
            
          </div> */}
        </div>
      </div>
      {/* <div class="grid grid-cols-1 px-4 xl:grid-cols-2 xl:gap-4">
        <div class="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 sm:p-6 dark:bg-gray-800 xl:mb-0">
          <div class="flow-root">
            <h3 class="text-xl font-semibold dark:text-white">
              Features & Services
            </h3>
            <p class="text-sm font-normal text-gray-500 dark:text-gray-400">
              You can manage which features you want to use
            </p>
            <div class="divide-y divide-gray-200 dark:divide-gray-700">
              <div class="flex items-center justify-between py-4">
                <div class="flex flex-col flex-grow">
                  <div class="text-lg font-semibold text-gray-900 dark:text-white">
                    Operaitor Call Handler
                  </div>
                  <div class="text-base font-normal text-gray-500 dark:text-gray-400">
                    Operaitor picks up the phone on your behalf
                  </div>
                </div>
                <label
                  for="call-handler"
                  class="relative flex items-center cursor-pointer"
                >
                  <input
                    type="checkbox"
                    id="call-handler"
                    class="peer sr-only"
                    checked
                  />
                  <div class="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:top-[2px] after:left-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none dark:border-gray-600 dark:bg-gray-700"></div>{" "}
                </label>
              </div>

              <div class="flex items-center justify-between py-4">
                <div class="flex flex-col flex-grow">
                  <div class="text-lg font-semibold text-gray-900 dark:text-white">
                    SMS appointment confirmation
                  </div>
                  <div class="text-base font-normal text-gray-500 dark:text-gray-400">
                    Sends a sms reminder prior to appointment
                  </div>
                </div>
                <label
                  for="sms-reminder"
                  class="relative flex items-center cursor-pointer"
                >
                  <input
                    type="checkbox"
                    id="sms-reminder"
                    class="peer sr-only"
                  />
                  <div class="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:top-[2px] after:left-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none dark:border-gray-600 dark:bg-gray-700"></div>{" "}
                </label>
              </div>

              <div class="flex items-center justify-between py-4">
                <div class="flex flex-col flex-grow">
                  <div class="text-lg font-semibold text-gray-900 dark:text-white">
                    Scheduling & Appointment Software
                  </div>
                  <div class="text-base font-normal text-gray-500 dark:text-gray-400">
                    Calendar software to manage appointments with ease
                  </div>
                </div>
                <label
                  for="meetups"
                  class="relative inline-flex cursor-pointer items-center "
                >
                  <input
                    type="checkbox"
                    id="meetups"
                    class="peer sr-only"
                    checked
                  />
                  <div class="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:top-[2px] after:left-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none dark:border-gray-600 dark:bg-gray-700"></div>{" "}
                </label>
              </div>

              <div class="flex items-center justify-between pt-4">
                <div class="flex flex-col flex-grow">
                  <div class="text-lg font-semibold text-gray-900 dark:text-white">
                    Insurance Claim
                  </div>
                  <div class="text-base font-normal text-gray-500 dark:text-gray-400">
                    Patient Insurance eligibility lookup
                  </div>
                </div>
                <label
                  for="new-messages"
                  class="relative flex items-center cursor-pointer"
                >
                  <input
                    type="checkbox"
                    id="new-messages"
                    class="peer sr-only"
                    disabled
                  />
                  <div class="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:top-[2px] after:left-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none dark:border-gray-600 dark:bg-gray-700"></div>{" "}
                </label>
              </div>
            </div>
            <div class="mt-6">
              <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                Save all
              </button>
            </div>
          </div>
        </div>
        <div class="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 sm:p-6 dark:bg-gray-800 xl:mb-0">
          <div class="flow-root">
            <h3 class="text-xl font-semibold dark:text-white">
              Email Notifications
            </h3>
            <p class="text-sm font-normal text-gray-500 dark:text-gray-400">
              You can set up Themesberg to get email notifications{" "}
            </p>
            <div class="divide-y divide-gray-200 dark:divide-gray-700">
              <div class="flex items-center justify-between py-4">
                <div class="flex flex-col flex-grow">
                  <div class="text-lg font-semibold text-gray-900 dark:text-white">
                    Rating reminders
                  </div>
                  <div class="text-base font-normal text-gray-500 dark:text-gray-400">
                    Send an email reminding me to rate an item a week after
                    purchase
                  </div>
                </div>
                <label
                  for="rating-reminders"
                  class="relative flex items-center cursor-pointer"
                >
                  <input
                    type="checkbox"
                    id="rating-reminders"
                    class="sr-only"
                  />
                  <div class="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:top-[2px] after:left-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none dark:border-gray-600 dark:bg-gray-700"></div>{" "}
                </label>
              </div>

              <div class="flex items-center justify-between py-4">
                <div class="flex flex-col flex-grow">
                  <div class="text-lg font-semibold text-gray-900 dark:text-white">
                    Item update notifications
                  </div>
                  <div class="text-base font-normal text-gray-500 dark:text-gray-400">
                    Send user and product notifications for you
                  </div>
                </div>
                <label
                  for="item-update"
                  class="relative flex items-center cursor-pointer"
                >
                  <input
                    type="checkbox"
                    id="item-update"
                    class="peer sr-only"
                  />
                  <div class="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:top-[2px] after:left-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none dark:border-gray-600 dark:bg-gray-700"></div>{" "}
                </label>
              </div>

              <div class="flex items-center justify-between py-4">
                <div class="flex flex-col flex-grow">
                  <div class="text-lg font-semibold text-gray-900 dark:text-white">
                    Item comment notifications
                  </div>
                  <div class="text-base font-normal text-gray-500 dark:text-gray-400">
                    Send me an email when someone comments on one of my items
                  </div>
                </div>
                <label
                  for="item-comment"
                  class="relative flex items-center cursor-pointer"
                >
                  <input
                    type="checkbox"
                    id="item-comment"
                    class="peer sr-only"
                  />
                  <div class="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:top-[2px] after:left-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none dark:border-gray-600 dark:bg-gray-700"></div>{" "}
                </label>
              </div>

              <div class="flex items-center justify-between pt-4">
                <div class="flex flex-col flex-grow">
                  <div class="text-lg font-semibold text-gray-900 dark:text-white">
                    Buyer review notifications
                  </div>
                  <div class="text-base font-normal text-gray-500 dark:text-gray-400">
                    Send me an email when someone leaves a review with their
                    rating
                  </div>
                </div>
                <label
                  for="buyer-rev"
                  class="relative flex items-center cursor-pointer"
                >
                  <input type="checkbox" id="buyer-rev" class="peer sr-only" />
                  <div class="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:top-[2px] after:left-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none dark:border-gray-600 dark:bg-gray-700"></div>{" "}
                </label>
              </div>
            </div>
            <div class="mt-6">
              <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                Save all
              </button>
            </div>
          </div>
        </div>
      </div> */}
      <ToastContainer />
    </div>
  );
}

export default SettingsPage;
