import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import {
  Scheduler,
  WeekView,
  MonthView,
  Toolbar,
  ViewSwitcher,
  Appointments,
  EditRecurrenceMenu,
  AppointmentTooltip,
  AppointmentForm,
  DragDropProvider,
  DateNavigator,
} from "@devexpress/dx-react-scheduler-material-ui";
import { ViewState, EditingState } from "@devexpress/dx-react-scheduler";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import AppointmentFormContainerBasic from "../components/Appointmentform";
import { connectProps } from "@devexpress/dx-react-core";
import {
  fetchAppointments,
  addAppointment,
  updateAppointment,
  deleteAppointment,
} from "../backend/supabaseService";

const PREFIX = "Demo";
const classes = {
  addButton: `${PREFIX}-addButton`,
};

const StyledFab = styled(Fab)(({ theme }) => ({
  [`&.${classes.addButton}`]: {
    position: "absolute",
    bottom: theme.spacing(3),
    right: theme.spacing(4),
  },
}));

function SchedulerContainer() {
  const [appointments, setAppointments] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [confirmationVisible, setConfirmationVisible] = useState(false);
  const [editingFormVisible, setEditingFormVisible] = useState(false);
  const [deletedAppointmentId, setDeletedAppointmentId] = useState(undefined);
  const [editingAppointment, setEditingAppointment] = useState(undefined);
  const [previousAppointment, setPreviousAppointment] = useState(undefined);
  const [addedAppointment, setAddedAppointment] = useState({});
  const [isNewAppointment, setIsNewAppointment] = useState(false);

  const startDayHour = 9;
  const endDayHour = 19;

  const loadAppointments = async () => {
    const data = await fetchAppointments();
    console.log("fetched appointments:  ", data);
    const formattedAppointments = data
      .map((appointment) => {
        // Check if this is a Curve Dental appointment
        if (appointment.source === "curveDental") {
          const startDate = new Date(appointment.startTime);

          const adjustedStartDate = new Date(
            startDate.getTime() + 4 * 60 * 60 * 1000
          );

          return {
            startDate: adjustedStartDate,
            endDate: new Date(
              adjustedStartDate.getTime() + appointment.length * 60000
            ),
            title: `${appointment.patient.firstName} ${appointment.patient.lastName}`,
            id: appointment.id,
            operatory: appointment.operatoryId,
            status: appointment.appointmentStatus,
            description: appointment.description,
            provider: appointment.providerName,
            readOnly: true,
            source: "curveDental",
            patient: appointment.patient,
          };
        }

        const timeRegex = /(\d{1,2}):(\d{2})\s(.*?)$/; // Regular expression for matching HH:MM a.m./p.m.
        const match = timeRegex.exec(appointment.time);
        if (!match) {
          console.error(`Invalid time format: ${appointment.time}`);
          return null;
        }
        let [_, hour, minute, period] = match; // Destructure the match array
        // Convert hour to 24-hour format if necessary
        if (period.toLowerCase() === "pm" && hour !== "12") {
          hour = String(parseInt(hour, 10) + 12);
        } else if (period.toLowerCase() === "am" && hour === "12") {
          hour = "00";
        }
        const startDate = new Date(`${appointment.date} ${hour}:${minute}`);

        // Check if the parsed date is valid
        if (isNaN(startDate.getTime())) {
          console.error(`Invalid date: ${appointment.date} ${hour}:${minute}`);
          return null;
        }
        // Create endDate by adding 30 minutes to startDate
        const endDate = new Date(startDate.getTime() + 30 * 60 * 1000); // Adding 30 minutes in milliseconds

        return {
          startDate,
          endDate,
          title: appointment.patient_name, // Use patient_name as the title
          id: appointment.id, // Use id as the identifier
          phone_number: appointment.phone_number,
          DoB: appointment.DoB,
          time: appointment.time,
        };
      })
      .filter((appointment) => appointment !== null); // Filter out null appointments

    console.log("data after format: ", formattedAppointments);

    setAppointments(formattedAppointments);
  };

  // loadAppointments();
  useEffect(() => {
    loadAppointments();
  }, []);

  const toggleEditingFormVisibility = () => {
    setEditingFormVisible(!editingFormVisible);
  };

  const toggleConfirmationVisible = () => {
    setConfirmationVisible(!confirmationVisible);
  };

  const commitDeletedAppointment = () => {
    setAppointments((prevAppointments) =>
      prevAppointments.filter(
        (appointment) => appointment.id !== deletedAppointmentId
      )
    );
    setDeletedAppointmentId(null);
    toggleConfirmationVisible();
  };

  const commitChanges = async ({ added, changed, deleted }) => {
    function formatTime(timeString) {
      if (!timeString) {
        console.error("Invalid time string: ", timeString);
        return ""; // Handle invalid time string gracefully
      }
      const [hours, minutes] = timeString.split(":").map(Number);
      const period = hours >= 12 ? "p.m." : "a.m.";
      const formattedHours = hours % 12 || 12;
      return `${formattedHours}:${minutes
        .toString()
        .padStart(2, "0")} ${period}`;
    }

    if (added) {
      const startingAddedId =
        appointments.length > 0
          ? Math.max(...appointments.map((appointment) => appointment.id)) + 1
          : 0;
      const newAppointment = { id: startingAddedId, ...added };
      console.log("NEW APPOINTMENT  :", newAppointment);

      const formattedTime = formatTime(newAppointment.time);
      const newAppointmentReformatted = {
        date: newAppointment.date,
        patient_name: newAppointment.title,
        id: newAppointment.id,
        phone_number: newAppointment.phone_number,
        DoB: newAppointment.DoB,
        time: formattedTime,
      };

      setAppointments([...appointments, newAppointment]);
      const error = await addAppointment(newAppointmentReformatted);
      if (error) {
        console.error("Error adding appointment to Supabase:", error);
      }
    }
    if (changed) {
      // fix change time for a.m. p.m.
      function changeFormatTime(timeString) {
        const match = /^(\d{1,2}):(\d{2})$/i.exec(timeString);
        if (!match) {
          console.error("Invalid time format:", timeString);
          return "";
        }
        let [, hours, minutes] = match;
        hours = parseInt(hours);
        // Determine the period (a.m. or p.m.)
        const period = hours >= 12 ? "p.m." : "a.m.";
        // Convert hours to 12-hour format
        const formattedHours = hours % 12 || 12;
        // Ensure minutes are two digits
        const formattedMinutes = minutes.padStart(2, "0");
        return `${formattedHours}:${formattedMinutes} ${period}`;
      }

      // id is third item
      let changedAppointmentkey = Object.keys(changed)[3];

      // id is not always thethird one when you drag and drop. its the first
      if (changedAppointmentkey === undefined) {
        changedAppointmentkey = Object.keys(changed)[0];
      }
      console.log("changed", changed);

      const changedAppointmentId = changed[changedAppointmentkey];
      console.log("changedAppointmentId: ", changedAppointmentId);

      let updatedAppointmentReformatted;

      const updatedAppointments = appointments.map((appointment) => {
        console.log("appointment time: ", appointments);
        if (appointment.id === changedAppointmentId) {
          const updatedAppointment = {
            ...appointment,
            ...changedAppointmentId,
          };
          console.log("time before: ", updatedAppointment.time);
          const formattedTime = changeFormatTime(changed.time);

          updatedAppointmentReformatted = {
            date: changed.startDate,
            patient_name: changed.title,
            id: updatedAppointment.id,
            phone_number: changed.phone_number,
            DoB: changed.DoB,
            time: formattedTime,
          };
          console.log("updated ", updatedAppointmentReformatted);
          return updatedAppointmentReformatted;
        } else {
          return appointment;
        }
      });

      // console.log("Updated Appointments:", updatedAppointments);
      setAppointments(updatedAppointments);

      // console.log("Reformatted:", updatedAppointmentReformatted);

      // Update the backend using the reformatted appointment
      async function updateBackend() {
        try {
          const error = await updateAppointment(
            changedAppointmentId,
            updatedAppointmentReformatted
          );
        } catch (error) {
          console.error(
            `Error updating appointment with id ${changedAppointmentId}:`,
            error
          );
        }
      }

      // Call the async function to update the backend
      updateBackend();
      loadAppointments();
    }
    if (deleted !== undefined) {
      setDeletedAppointmentId(deleted);
      toggleConfirmationVisible();

      const error = await deleteAppointment(deleted);
      if (error) {
        console.error(`Error deleting appointment with id ${deleted}:`, error);
      }
    }
  };

  const appointmentForm = connectProps(AppointmentFormContainerBasic, () => {
    const currentAppointment =
      appointments.find(
        (appointment) =>
          editingAppointment && appointment.id === editingAppointment.id
      ) || addedAppointment;

    const cancelAppointment = () => {
      if (isNewAppointment) {
        setEditingAppointment(previousAppointment);
        setIsNewAppointment(false);
      }
    };

    return {
      visible: editingFormVisible,
      appointmentData: currentAppointment,
      commitChanges,
      visibleChange: toggleEditingFormVisibility,
      onEditingAppointmentChange: setEditingAppointment,
      cancelAppointment,
    };
  });

  return (
    <Paper>
      <Scheduler
        data={appointments}
        defaultCurrentDate={currentDate}
        timeZone={"America/New_York"}
        height={660}
      >
        <ViewState defaultCurrentDate={currentDate} />
        <EditingState
          onCommitChanges={commitChanges}
          onEditingAppointmentChange={setEditingAppointment}
          onAddedAppointmentChange={(appointment) => {
            setAddedAppointment(appointment);
            setPreviousAppointment(editingAppointment);
            setEditingAppointment(undefined);
            setIsNewAppointment(true);
          }}
        />
        <WeekView startDayHour={startDayHour} endDayHour={endDayHour} />
        <MonthView />
        <EditRecurrenceMenu />
        <Appointments />
        <AppointmentTooltip showOpenButton showDeleteButton showCloseButton />
        <Toolbar />
        <ViewSwitcher />
        <DateNavigator />

        <AppointmentForm
          overlayComponent={appointmentForm}
          visible={editingFormVisible}
          onVisibilityChange={toggleEditingFormVisibility}
        />
        {/* <DragDropProvider /> */}
      </Scheduler>

      <Dialog open={confirmationVisible} onClose={toggleConfirmationVisible}>
        <DialogTitle>Delete Appointment</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this appointment?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={toggleConfirmationVisible}
            color="primary"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={commitDeletedAppointment}
            color="secondary"
            variant="outlined"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <StyledFab
        color="primary"
        className={classes.addButton}
        onClick={() => {
          setEditingFormVisible(true);
          setEditingAppointment(undefined);
          setAddedAppointment({
            startDate: new Date(currentDate).setHours(startDayHour),
            endDate: new Date(currentDate).setHours(startDayHour + 1),
          });
        }}
      >
        <AddIcon />
      </StyledFab>
    </Paper>
  );
}

export default SchedulerContainer;
