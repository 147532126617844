import React from "react";
import {
  Navbar,
  MobileNav,
  Typography,
  Button,
  IconButton,
} from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import logo from "./assets/logo.jpeg";

function Navigation() {
  const navigate = useNavigate();

  const [openNav, setOpenNav] = useState(false);

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );
  }, []);

  const navList = (
    <ul className="mb-4 mt-2 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">
      {/* <Typography
        as="li"
        variant="small"
        color="black"
        className="p-1 font-normal"
      >
        <a href="#" className="flex items-center">
          Pages
        </a>
      </Typography> */}
      <Typography
        as="li"
        variant="large"
        color="black"
        className="p-1 font-normal"
      >
        <a href="/pricing" className="flex items-center">
          Pricing
        </a>
      </Typography>

      <Typography
        as="li"
        variant="large"
        color="black"
        className="p-1 font-normal"
      >
        <a href="/about" className="flex items-center">
          About Us
        </a>
      </Typography>
      <Typography
        as="li"
        variant="large"
        color="black"
        className="p-1 font-normal"
      >
        <a href="/howitWorks" className="flex items-center">
          How it works
        </a>
      </Typography>
    </ul>
  );

  return (
    <Navbar className="mx-auto max-w-screen-xl border-none shadow-none py-2 px-4 lg:px-28 lg:py-0 ">
      <div className="container mx-auto flex items-center justify-between text-black">
        <Typography
          as="a"
          href="/"
          className="mr-4 cursor-pointer py-1.5 font-medium"
        >
          <img
            src={logo}
            className="h-8 hover:scale-105 transition-transform duration-300 ease-in-out"
            alt="Logo"
          />
        </Typography>
        <div className="hidden lg:block">{navList}</div>
        <Button
          onClick={() => navigate("/login")}
          variant="gradient"
          size="sm"
          className="text-white hidden w-30 h-8 lg:inline-block bg-[#26add0] hover:bg-[#4bc0de]"
          style={{
            boxShadow: "none",
            border: "none",
            paddingBottom: "4px",
            paddingTop: "4px",
          }}
        >
          <span className="text-sm">Log In</span>
        </Button>
        <IconButton
          variant="text"
          className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
          ripple={false}
          onClick={() => setOpenNav(!openNav)}
        >
          {openNav ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              className="h-6 w-6"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          )}
        </IconButton>
      </div>
      <MobileNav open={openNav}>
        <div className="container mx-auto">
          {navList}
          <Button
            onClick={() => navigate("/login")}
            variant="gradient"
            size="sm"
            fullWidth
            className="btn-sm text-white-200 bg-[#26add0] hover:bg-[#84d0df] w-full my-2"
          >
            <span>Log In</span>
          </Button>
        </div>
      </MobileNav>
    </Navbar>
  );
}

export default Navigation;
