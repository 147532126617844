import React, { useState } from "react";
import { createClient } from "@supabase/supabase-js";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_KEY;
const supabase = createClient(supabaseUrl, supabaseAnonKey);

const stateOptions = [
  { code: "", name: "Select" },
  { code: "AL", name: "Alabama" },
  { code: "AK", name: "Alaska" },
  { code: "AZ", name: "Arizona" },
  { code: "AR", name: "Arkansas" },
  { code: "CA", name: "California" },
  { code: "CO", name: "Colorado" },
  { code: "CT", name: "Connecticut" },
  { code: "DE", name: "Delaware" },
  { code: "FL", name: "Florida" },
  { code: "GA", name: "Georgia" },
  { code: "HI", name: "Hawaii" },
  { code: "ID", name: "Idaho" },
  { code: "IL", name: "Illinois" },
  { code: "IN", name: "Indiana" },
  { code: "IA", name: "Iowa" },
  { code: "KS", name: "Kansas" },
  { code: "KY", name: "Kentucky" },
  { code: "LA", name: "Louisiana" },
  { code: "ME", name: "Maine" },
  { code: "MD", name: "Maryland" },
  { code: "MA", name: "Massachusetts" },
  { code: "MI", name: "Michigan" },
  { code: "MN", name: "Minnesota" },
  { code: "MS", name: "Mississippi" },
  { code: "MO", name: "Missouri" },
  { code: "MT", name: "Montana" },
  { code: "NE", name: "Nebraska" },
  { code: "NV", name: "Nevada" },
  { code: "NH", name: "New Hampshire" },
  { code: "NJ", name: "New Jersey" },
  { code: "NM", name: "New Mexico" },
  { code: "NY", name: "New York" },
  { code: "NC", name: "North Carolina" },
  { code: "ND", name: "North Dakota" },
  { code: "OH", name: "Ohio" },
  { code: "OK", name: "Oklahoma" },
  { code: "OR", name: "Oregon" },
  { code: "PA", name: "Pennsylvania" },
  { code: "RI", name: "Rhode Island" },
  { code: "SC", name: "South Carolina" },
  { code: "SD", name: "South Dakota" },
  { code: "TN", name: "Tennessee" },
  { code: "TX", name: "Texas" },
  { code: "UT", name: "Utah" },
  { code: "VT", name: "Vermont" },
  { code: "VA", name: "Virginia" },
  { code: "WA", name: "Washington" },
  { code: "WV", name: "West Virginia" },
  { code: "WI", name: "Wisconsin" },
  { code: "WY", name: "Wyoming" },
];

const NewPatientForm = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    lastName: "",
    firstName: "",
    preferredName: "",
    gender: "",
    familyStatus: "",
    dob_at: "",
    email: "",
    status: "",
    home_phone: "",
    cell_phone: "",
    work_phone: "",
    allergies: "",
    conditions: "",
    preferred_language: "",
    address: {
      line1: "",
      city: "",
      state: "",
      country: "",
      zip: "",
    },
    billing_address: {
      line1: "",
      city: "",
      state: "",
      country: "",
      zip: "",
    },
    preferred_contact_method: "",
    insurance: {
      provider: "",
      policyNumber: "",
      groupNumber: "",
      primaryInsured: {
        name: "",
        relationship: "",
        dateOfBirth: "",
      },
    },
    insuranceAuthorization: false,
    dentalInfo: {
      immediateConcern: "",
      previousDentist: "",
      previousDentistPhone: "",
      lastExamDate: "",
      lastXrayDate: "",
      smileChangeDesire: "",
    },
    hipaaAuthorization: false,
    consentForInternetCommunication: false,
    signature: "",
    signatureDate: "",
  });

  const submitNewPatientForm = async (e) => {
    e.preventDefault();

    const newPatientData = {
      last_name: formData.lastName,
      first_name: formData.firstName,
      preferred_name: formData.preferredName,
      gender: formData.gender,
      family_status: formData.familyStatus,
      dob_at: formData.dob_at,
      email: formData.email,
      status: formData.status,
      home_phone: formData.home_phone,
      cell_phone: formData.cell_phone,
      work_phone: formData.work_phone,
      allergies: formData.allergies,
      conditions: formData.conditions,
      preferred_language: formData.preferred_language,
      address_line1: formData.address.line1,
      address_city: formData.address.city,
      address_state: formData.address.state,

      address_zip: formData.address.zip,
      billing_address_line1: formData.billing_address.line1,
      billing_address_city: formData.billing_address.city,
      billing_address_state: formData.billing_address.state,

      billing_address_zip: formData.billing_address.zip,
      preferred_contact_method: formData.preferred_contact_method,
      insurance_provider: formData.insurance.provider,
      insurance_policy_number: formData.insurance.policyNumber,
      insurance_group_number: formData.insurance.groupNumber,
      insurance_primary_insured_name: formData.insurance.primaryInsured.name,
      insurance_primary_insured_relationship:
        formData.insurance.primaryInsured.relationship,
      insurance_primary_insured_dob:
        formData.insurance.primaryInsured.dateOfBirth,
      insurance_primary_insured_employer:
        formData.insurance.primaryInsured.employerName,
      insurance_authorization: formData.insuranceAuthorization,
      dental_immediate_concern: formData.dentalInfo.immediateConcern,
      dental_previous_dentist: formData.dentalInfo.previousDentist,
      dental_previous_dentist_phone: formData.dentalInfo.previousDentistPhone,
      dental_last_exam_date: formData.dentalInfo.lastExamDate,
      dental_last_xray_date: formData.dentalInfo.lastXrayDate,
      dental_smile_change_desire: formData.dentalInfo.smileChangeDesire,
      hipaaAuthorization: formData.hipaaAuthorization,
      consentForInternetCommunication: formData.consentForInternetCommunication,
      signature: formData.signature,
      signatureDate: formData.signatureDate,
    };

    try {
      const { error } = await supabase
        .from("new_patient_registrations")
        .insert([newPatientData]);

      if (error) throw error;
      toast.success("Patient data submitted successfully!");
      navigate("/");
    } catch (error) {
      toast.error("An error occurred while submitting the form.");
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData((prevState) => {
      const newState = { ...prevState };
      const nameParts = name.split(".");

      if (nameParts.length === 1) {
        newState[name] = type === "checkbox" ? checked : value;
      } else {
        let current = newState;
        for (let i = 0; i < nameParts.length - 1; i++) {
          if (!current[nameParts[i]]) {
            current[nameParts[i]] = {};
          }
          current = current[nameParts[i]];
        }
        current[nameParts[nameParts.length - 1]] =
          type === "checkbox" ? checked : value;
      }

      return newState;
    });
  };

  return (
    <div className="bg-gray-100 min-h-screen">
      <header className="bg-blue-900 text-white p-4 flex justify-between items-center">
        <h1 className="text-2xl font-bold">Line Dental</h1>
        {/* <select className="bg-white text-black p-1 rounded">
          <option>Select Language</option>
        </select> */}
      </header>

      <main className="container mx-auto p-4 max-w-xlg">
        <h2 className="text-xl text-blue-800 mb-4">
          NEW PATIENT Registration Form
        </h2>

        <div className="bg-white p-6 rounded-lg shadow-md">
          <h3 className="text-2xl font-semibold mb-6">
            Welcome to our Practice
          </h3>

          <form>
            <div className="mb-4">
              <label className="block text-gray-700 mb-2">Patient Name:</label>
              <div className="flex space-x-4">
                <input
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  placeholder="Last Name"
                  className="flex-1 p-2 border rounded"
                />
                <input
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  placeholder="First Name"
                  className="flex-1 p-2 border rounded"
                />
              </div>
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 mb-2">
                Preferred Name:
              </label>
              <input
                type="text"
                name="preferredName"
                value={formData.preferredName}
                onChange={handleInputChange}
                placeholder="Enter preferred name"
                className="w-full p-2 border rounded"
              />
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 mb-2">Gender:</label>
              <div className="flex space-x-4">
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    name="gender"
                    value="Male"
                    checked={formData.gender === "Male"}
                    onChange={handleInputChange}
                    className="form-radio"
                  />
                  <span className="ml-2">Male</span>
                </label>
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    name="gender"
                    value="Female"
                    checked={formData.gender === "Female"}
                    onChange={handleInputChange}
                    className="form-radio"
                  />
                  <span className="ml-2">Female</span>
                </label>
              </div>
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 mb-2">Date of Birth:</label>
              <input
                type="date"
                name="dob_at"
                value={formData.dob_at}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
              />
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 mb-2">Email:</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="Enter email address"
                className="w-full p-2 border rounded"
              />
            </div>

            <div className="flex space-x-4 mb-4">
              <div className="flex-1">
                <label className="block text-gray-700 mb-2">Home Phone:</label>
                <input
                  type="text"
                  name="home_phone"
                  value={formData.home_phone}
                  onChange={handleInputChange}
                  placeholder="+16461230123"
                  className="w-full p-2 border rounded"
                />
              </div>
              <div className="flex-1">
                <label className="block text-gray-700 mb-2">Cell Phone:</label>
                <input
                  type="text"
                  name="cell_phone"
                  value={formData.cell_phone}
                  onChange={handleInputChange}
                  placeholder="+16461230123"
                  className="w-full p-2 border rounded"
                />
              </div>
              <div className="flex-1">
                <label className="block text-gray-700 mb-2">Work Phone:</label>
                <input
                  type="text"
                  name="work_phone"
                  value={formData.work_phone}
                  onChange={handleInputChange}
                  placeholder="+16461230123"
                  className="w-full p-2 border rounded"
                />
              </div>
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 mb-2">
                Preferred Contact Method:
              </label>
              <input
                type="text"
                name="preferred_contact_method"
                value={formData.preferred_contact_method}
                onChange={handleInputChange}
                placeholder="Call, Message, Email"
                className="w-full p-2 border rounded"
              />
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 mb-2">Allergies:</label>
              <input
                type="text"
                name="allergies"
                value={formData.allergies}
                onChange={handleInputChange}
                placeholder="nuts, milk"
                className="w-full p-2 border rounded"
              />
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 mb-2">Conditions:</label>
              <input
                type="text"
                name="conditions"
                value={formData.conditions}
                onChange={handleInputChange}
                placeholder="Fear of Needles"
                className="w-full p-2 border rounded"
              />
            </div>

            <div className="mb-6">
              <h3 className="text-xl font-semibold mb-4">Address:</h3>

              <div className="mb-4">
                <label className="block text-gray-600 mb-2">Address</label>
                <input
                  type="text"
                  name="address.line1"
                  value={formData.address.line1}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded"
                />
              </div>

              <div className="flex space-x-4">
                <div className="flex-1">
                  <label className="block text-gray-600 mb-2">City:</label>
                  <input
                    type="text"
                    name="address.city"
                    value={formData.address.city}
                    onChange={handleInputChange}
                    className="w-full p-2 border rounded"
                  />
                </div>
                <div className="flex-1">
                  <label className="block text-gray-600 mb-2">State:</label>
                  <select
                    name="address.state"
                    value={formData.address.state}
                    onChange={handleInputChange}
                    className="w-full p-2 border rounded appearance-none"
                  >
                    {stateOptions.map((state) => (
                      <option key={state.code} value={state.code}>
                        {state.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex-1">
                  <label className="block text-gray-600 mb-2">Zip Code:</label>
                  <input
                    type="text"
                    name="address.zip"
                    value={formData.address.zip}
                    onChange={handleInputChange}
                    className="w-full p-2 border rounded"
                  />
                </div>
              </div>
            </div>

            <div className="mb-6">
              <h3 className="text-xl font-semibold mb-4">Billing Address:</h3>

              <div className="mb-4">
                <label className="block text-gray-600 mb-2">Address</label>
                <input
                  type="text"
                  name="billing_address.line1"
                  value={formData.billing_address.line1}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded"
                />
              </div>

              <div className="flex space-x-4">
                <div className="flex-1">
                  <label className="block text-gray-600 mb-2">City:</label>
                  <input
                    type="text"
                    name="billing_address.city"
                    value={formData.billing_address.city}
                    onChange={handleInputChange}
                    className="w-full p-2 border rounded"
                  />
                </div>
                <div className="flex-1">
                  <label className="block text-gray-600 mb-2">State:</label>
                  <select
                    name="billing_address.state"
                    value={formData.billing_address.state}
                    onChange={handleInputChange}
                    className="w-full p-2 border rounded appearance-none"
                  >
                    {stateOptions.map((state) => (
                      <option key={state.code} value={state.code}>
                        {state.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex-1">
                  <label className="block text-gray-600 mb-2">Zip Code:</label>
                  <input
                    type="text"
                    name="billing_address.zip"
                    value={formData.billing_address.zip}
                    onChange={handleInputChange}
                    className="w-full p-2 border rounded"
                  />
                </div>
              </div>
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 mb-2">
                Emergency Contact Phone Number:
              </label>
              <input
                type="text"
                name="emergency_contact"
                value={formData.emergency_contact}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
              />
            </div>

            <div className="mb-6">
              <h3 className="text-xl font-semibold mb-4">
                Insurance Information
              </h3>

              <div className="mb-4">
                <label className="block text-gray-700 mb-2">
                  Insurance Provider:
                </label>
                <input
                  type="text"
                  name="insurance.provider"
                  value={formData.insurance.provider}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded"
                />
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 mb-2">
                  Policy Number:
                </label>
                <input
                  type="text"
                  name="insurance.policyNumber"
                  value={formData.insurance.policyNumber}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded"
                />
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 mb-2">
                  Group Number:
                </label>
                <input
                  type="text"
                  name="insurance.groupNumber"
                  value={formData.insurance.groupNumber}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded"
                />
              </div>

              <div className="mb-4">
                <h4 className="text-lg font-semibold mb-2">Primary Insured</h4>

                <div className="mb-2">
                  <label className="block text-gray-700 mb-1">Name:</label>
                  <input
                    type="text"
                    name="insurance.primaryInsured.name"
                    value={formData.insurance.primaryInsured.name}
                    onChange={handleInputChange}
                    className="w-full p-2 border rounded"
                  />
                </div>

                <div className="mb-2">
                  <label className="block text-gray-700 mb-1">
                    Relationship to Patient:
                  </label>
                  <input
                    type="text"
                    name="insurance.primaryInsured.relationship"
                    value={formData.insurance.primaryInsured.relationship}
                    onChange={handleInputChange}
                    className="w-full p-2 border rounded"
                  />
                </div>

                <div className="mb-2">
                  <label className="block text-gray-700 mb-1">
                    Date of Birth:
                  </label>
                  <input
                    type="date"
                    name="insurance.primaryInsured.dateOfBirth"
                    value={formData.insurance.primaryInsured.dateOfBirth}
                    onChange={handleInputChange}
                    className="w-full p-2 border rounded"
                  />
                </div>
              </div>
            </div>

            {/* Insurance Authorization */}
            <div className="mb-6">
              <h3 className="text-xl font-semibold mb-4">
                Insurance Authorization
              </h3>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  name="insuranceAuthorization"
                  checked={formData.insuranceAuthorization}
                  onChange={handleInputChange}
                  className="mr-2"
                  required
                />
                <span className="text-sm">
                  I authorize my insurance company to pay the dentist all
                  insurance benefits rendered. I authorize the use of this
                  electronic signature on all insurance submissions. I authorize
                  the dentist to release all information necessary to secure the
                  payment of benefits. I understand that I am financially
                  responsible for all charges whether or not paid by insurance.
                </span>
              </label>
            </div>

            {/* Dental Information */}
            <div className="mb-6">
              <h3 className="text-xl font-semibold mb-4">Dental Information</h3>

              <div className="mb-4">
                <label className="block text-gray-700 mb-2">
                  What is your immediate concern?
                </label>
                <textarea
                  name="dentalInfo.immediateConcern"
                  value={formData.dentalInfo.immediateConcern}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded"
                  rows="3"
                />
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 mb-2">
                  Previous Dentist Name:
                </label>
                <input
                  type="text"
                  name="dentalInfo.previousDentist"
                  value={formData.dentalInfo.previousDentist}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded"
                />
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 mb-2">
                  Previous Dentist Phone Number:
                </label>
                <input
                  type="tel"
                  name="dentalInfo.previousDentistPhone"
                  value={formData.dentalInfo.previousDentistPhone}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded"
                />
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 mb-2">
                  Date of most recent dental exam:
                </label>
                <input
                  type="date"
                  name="dentalInfo.lastExamDate"
                  value={formData.dentalInfo.lastExamDate}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded"
                />
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 mb-2">
                  Date of most recent dental x-rays:
                </label>
                <input
                  type="date"
                  name="dentalInfo.lastXrayDate"
                  value={formData.dentalInfo.lastXrayDate}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded"
                />
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 mb-2">
                  Is there anything about the appearance of your smile that you
                  would like to change?
                </label>
                <textarea
                  name="dentalInfo.smileChangeDesire"
                  value={formData.dentalInfo.smileChangeDesire}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded"
                  rows="3"
                />
              </div>
            </div>

            <div className="mb-6">
              <h3 className="text-xl font-semibold mb-4">
                HIPAA Acknowledgement
              </h3>
              <label className="items-center">
                <span className="text-sm">
                  I understand that I may inspect or copy the protected health
                  information described by this authorization. I understand that
                  at any time, this authorization may be revoked, when the
                  office that receives this authorization receives a written
                  revocation, although that revocation will not be effective as
                  to the disclosure of records whose release I have previously
                  authorized, or where other action has been taken in reliance
                  on an authorization I have signed. <br />
                  <br /> I understand that my health care and the payment for my
                  healthcare will not be affected if I refuse to sign this form.
                  I understand that information used or disclosed, pursuant to
                  this authorization, could be subject to re-disclosure by the
                  recipient and, if so, may not be subject to federal or state
                  law protecting its confidentiality, I allow this practice to
                  disclose my Protective Health Information to the following
                  individuals: (This information could include: Name, Diagnosis,
                  Test Results, Images and Account Information.)
                </span>
                <div>
                  <input
                    type="checkbox"
                    name="hipaaAuthorization"
                    className="mr-2"
                    checked={formData.hipaaAuthorization}
                    onChange={handleInputChange}
                    required
                  />
                  <span className="text-sm">
                    I have read the information above regarding the secured
                    uploading of patient information to the web site for the
                    dental practice, and grant the dental practice permission to
                    securely upload my patient information to the web site. This
                    will serve as my electronic signature.
                  </span>
                </div>
              </label>
            </div>

            <div className="mb-6">
              <h3 className="text-xl font-semibold mb-4">
                Consent for Internet Communications
              </h3>

              <div className="mb-6">
                <label className=" items-center">
                  <span className="text-sm">
                    I grant my permission to the dental practice to upload and
                    store confidential patient information (including account
                    information, appointment information and clinical
                    information) to the secured web site for the dental
                    practice. I understand that, for security purposes, the site
                    requires a user ID and password for access and use. I also
                    understand the dental practice and I are responsible for
                    maintaining the strict confidentiality of any ID and
                    password assigned to me; and that the dental practice is not
                    liable for any charges, damages, or losses that may be
                    incurred or suffered as a result of my failure to maintain
                    confidentiality. I understand the dental practice is not
                    liable for any harm related to the theft of my ID and
                    password, my disclosure of my ID and password, or my
                    authorization to allow another person or entity to access
                    and use the dental practice web site with my ID and
                    password. I also agree to immediately notify the dental
                    practice of any unauthorized use of my ID or of any other
                    need to deactivate my ID due to security concerns. <br />
                    <br /> I also understand that State and Federal laws, as
                    well as ethical and licensure requirements impose
                    obligations with respect to patient confidentiality that
                    limit the ability to make use of certain services or to
                    transmit certain information to third parties. I understand
                    the dental practice will represent and warrant that they
                    will, at all times during the terms of this Agreement and
                    thereafter, comply with all laws directly or indirectly
                    applicable that may now or hereafter govern the gathering,
                    use, transmission, processing, receipt, reporting,
                    disclosure, maintenance, and storage of my information, and
                    use their best efforts to cause all persons or entities
                    under their direction or control to comply with such laws. I
                    agree that the dental practice has the right to monitor,
                    retrieve, store, upload and use my information in connection
                    with the operation of such services, and is acting on my
                    behalf in uploading my patient information. I understand the
                    dental practice will use commercially reasonable efforts to
                    maintain the confidentiality of all patient information that
                    is uploaded to the web site on my behalf. I understand the
                    dental practice CANNOT AND DOES NOT ASSUME ANY
                    RESPONSIBILITY FOR MY USE OR MISUSE OF PATIENT INFORMATION
                    OR OTHER INFORMATION TRANSMITTED, MONITORED, STORED,
                    UPLOADED OR RECEIVED USING THE SITE OR THE SERVICES.
                  </span>
                  <div>
                    <input
                      type="checkbox"
                      name="consentForInternetCommunication"
                      checked={formData.consentForInternetCommunication}
                      onChange={handleInputChange}
                      className="mr-2"
                      required
                    />
                    <span className="text-sm">
                      I have read the information above regarding the secured
                      uploading of patient information to the web site for the
                      dental practice, and grant the dental practice permission
                      to securely upload my patient information to the web site.
                      This will serve as my electronic signature.
                    </span>
                  </div>
                </label>
              </div>
            </div>

            <div className="flex flex-col md:flex-row mt-4 space-y-4 md:space-y-0 md:space-x-4">
              <div className="w-full md:w-1/2">
                <label
                  htmlFor="signature"
                  className="block text-sm font-medium mb-1"
                >
                  Signature:
                </label>
                <input
                  type="text"
                  id="signature"
                  name="signature"
                  value={formData.signature}
                  onChange={handleInputChange}
                  className="mt-1 block w-full bg-gray-200 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                  placeholder="Type your name here"
                  required
                />
              </div>

              <div className="w-full md:w-1/2">
                <label
                  htmlFor="signatureDate"
                  className="block text-sm font-medium mb-1"
                >
                  Date:
                </label>
                <input
                  type="text"
                  name="signatureDate"
                  onChange={handleInputChange}
                  className="mt-1 block w-full bg-gray-200 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                  value={formData.signatureDate}
                  placeholder="YYYY-MM-DD"
                />
              </div>
            </div>

            <button
              type="submit"
              className="bg-blue-500 text-white py-2 mt-4 px-4 rounded hover:bg-blue-600"
              onClick={submitNewPatientForm}
            >
              Submit
            </button>
          </form>
        </div>
      </main>
      <ToastContainer />
    </div>
  );
};

export default NewPatientForm;
